import React, { Component } from 'react';
import './App.css';
import axios from 'axios';
import weeksList from './weeksList';
import Sidebar from './Sidebar.js';
import NumberFormat from 'react-number-format';
import {NavLink} from 'react-router-dom';

class DashboardSortByUsers extends Component {

  constructor(){
    super();
    var week = window.location.href.substr(-1);
    this.state={
      weeksList: weeksList,
      thisWeeksData: {Start:'',End:'',data:{data:[]}},
      week: week,
      arrayOfUserToRenderArray: [],
    }
  }


    //console.log(resultArray);




  async componentWillMount(){

    var weeklyTransactionsArray = [];
    var week = window.location.href.substr(-1);
    var i = Number(week);
    console.log(i,'logging i');

     var thisWeeksData = {Start:'2/26/2000 08:00:00',End:'01/01/2099 08:00:00'};
  //   console.log(weeksList[i].Start,'weeksList[i].Start',weeksList[i].End,'weeksList[i].End');
     var url = 'https://api.niftygateway.com/dashboard/date-range-co/?start_date=2/25/2018 08:00:00&end_date=9/25/2022 08:00:00';
    // console.log(url,'logging url');
     await axios.get(url).then(res => {
    //   console.log(res,'logging res from date range number'+i);
       thisWeeksData.data = res.data;
       // var newArray = res.data.data.reverse();
       // thisWeeksData.data.data = newArray;


       this.setState({thisWeeksData:thisWeeksData});


    //    weeklyTransactionsArray.push(thisWeeksData);
       console.log(thisWeeksData,'thisWeeksData')

      // this.setState({pa  ymentSourcesArray: res.data,selectingPage:3,selectedPaymentIndex:newCardIndex,paymentSourcesLoading:false,isCreditCardBeingAdded:false});
     }).catch(error => {
       console.log(error.response,'error response')
       throw error;
     });



     var arrayOfUsersToRender = [];
     var arraysOfUsersEmails = [];

    for(var p=0;p<thisWeeksData.data.data.length;p++){

      if(arraysOfUsersEmails.indexOf(thisWeeksData.data.data[p].UserSendingTransaction.email)===-1){


                var newUserEmail = thisWeeksData.data.data[p].UserSendingTransaction.email;
                var newUserPurhcaseCount = 0;
                var newUserTotalSpending = 0;

                for (var r=0;r<thisWeeksData.data.data.length;r++){

                    if(thisWeeksData.data.data[r].UserSendingTransaction.email===newUserEmail){
                      newUserTotalSpending = newUserTotalSpending + thisWeeksData.data.data[r].TotalAmountToCharge;
                      newUserPurhcaseCount ++;
                    }

                }

              var newUserObject = {Email:thisWeeksData.data.data[p].UserSendingTransaction.email,PurchaseCount:newUserPurhcaseCount,TotalSpending:newUserTotalSpending}
              arraysOfUsersEmails.push(thisWeeksData.data.data[p].UserSendingTransaction.email);
              arrayOfUsersToRender.push(newUserObject);

        }

      }


      var newArrayToRender = arrayOfUsersToRender.sort(function(a, b){
        return new Number(a.TotalSpending) > new Number(b.TotalSpending);
      });

      arrayOfUsersToRender.sort((a,b) => a.TotalSpending - b.TotalSpending);

      this.setState({arrayOfUserToRenderArray:arrayOfUsersToRender.reverse()});
  //
  //
  //
  //
  //   // console.log(weeklyTransactionsArray,'weeklyTransactionsArray');
  //
  // // console.log(weeklyTransactionsArray,'weeklyTransactionsArray');
  // // this.setState({weeklyTransactionsArray: weeklyTransactionsArray});
  // // console.log(weeklyTransactionsArray,'weeklyTransactionsArray');
  // //
  // // var numOfAxie = 0;
  // // var axiePurchasesArray = [];
  // // var numOfGU = 0;
  // // var gUPurchasesArray = [];
  // // var numOfOther = 0;
  // //
  // // var sumTotal = 0;
  // //
  // // for (var i=0;i<thisWeeksData.data.data.length;i++){
  // //   if(thisWeeksData.data.data[i].AppForPurchaseObject==='AxieInfinityLand'){
  // //     numOfAxie++;
  // //   } else if (thisWeeksData.data.data[i].AppForPurchaseObject==='GodsUnchained'){
  // //     numOfGU++;
  // //   } else {
  // //     numOfOther++;
  // //   }
  // //
  // //   sumTotal = sumTotal + thisWeeksData.data.data[i].TotalAmountToCharge;
  // // }
  //
  // var percentages = {Axie:numOfAxie/(thisWeeksData.data.data.length),GodsUnchained:(numOfGU/thisWeeksData.data.data.length),Other:(numOfOther/thisWeeksData.data.data.length)};
  //
  // this.setState({totalWeeklyRev:sumTotal,percentageAxie:percentages.Axie,percentageGU:percentages.GodsUnchained,percentageOther:percentages.Other});

  }



  fetchDataForATimePeriod(startDate,endDate){
    axios.get()
  }


  render() {
   return (

    <div >

    <div style={{width:'100%',position:'absolute',top:0,height:'120px',display:'flex',flexDirection:"column",alignItems:'center',justifyContent:"center"}}>
    <text className="bodyMedium" style={{fontSize:'55px'}}>All Time - Sorted by User</text>

     </div>
     <Sidebar />

     <div style={{position:"absolute",top:150,left:650,display:'flex',flexDirection:'column',alignItems:'flex-start',justifyContent:'flex-start'}}>
         <text className="BodyNormal" style={{fontSize:'13px'}}>Axie Infinity: {Number(100*this.state.percentageAxie).toFixed(2)} </text>
         <text className="BodyNormal" style={{fontSize:'13px',marginTop:'15px'}}>Gods Unchained: {Number(100*this.state.percentageGU).toFixed(2)} </text>
         <text className="BodyNormal" style={{fontSize:'13px',marginTop:'15px'}}>Other: {Number(100*this.state.percentageOther).toFixed(2)} </text>
     </div>

      <text className="BodyNormal" style={{fontSize:'18px',position:'absolute',top:200,left:950}}>Total: {<NumberFormat value={(this.state.totalWeeklyRev/100).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />}</text>

     <text className="BodyNormal" style={{fontSize:'30px',position:'absolute',top:180,left:350}}>{this.state.thisWeeksData.Start.substr(5,5)} to {this.state.thisWeeksData.End.substr(5,5)} </text>

    <div style={{position:'absolute',top:240,left:290,width:'900px',borderStyle:"solid",borderWidth:'0px',borderTopWidth:"1px",borderColor:'lightgrey',height:'1px'}}> </div>

    <div style={{position:'absolute',top:270,left:250,width:'1000px',display:"flex",flexDirection:'column',alignItems:'center',justifyContent:'flex-start',}}>

    {this.state.arrayOfUserToRenderArray.map((l,i) => (
    <div style={{width:'850px',display:"flex",flexDirection:"row",alignItems:'flex-start',justifyContent:'flex-start'}}>
    <div style={{width:'900px',borderStyle:'solid',borderWidth:'1px',borderBottomWidth:i+1===this.state.thisWeeksData.data.data.length?'1px':'0px',borderColor:'grey',height:'35px',display:'flex',flexDirection:'row',alignItems:'flex-start',justifyContent:'flex-start'}}>
      <div style={{height:'35px',width:'50px',display:'flex',flexDirection:'row',alignItems:'flex-start',justifyContent:'flex-start',borderStyle:'solid',borderWidth:'0px',borderRightWidth:'1px',borderColor:'grey'}}>
        <text className="BodyLight" style={{fontSize:'17px',color:'grey',padding:'5px',width:'50px',textAlign:'left',marginLeft:'8px'}}>{i+1}</text>

      </div>
      <div style={{height:'35px',width:'255px',display:'flex',flexDirection:'row',alignItems:'flex-start',justifyContent:'flex-start',borderStyle:'solid',borderWidth:'0px',borderRightWidth:'1px',borderColor:'grey'}}>
        <NavLink to={"/Dashboard/user/"+l.Email}>
          <text className="BodyLight" style={{fontSize:'14px',color:'grey',padding:'5px',width:'215px',textDecoration:'underline',color:"blue",textAlign:'left',marginLeft:'8px'}}>{l.Email}</text>
          </NavLink>
      </div>
      <div style={{height:'35px',width:'140px',display:'flex',flexDirection:'row',alignItems:'flex-start',justifyContent:'flex-start',borderStyle:'solid',borderWidth:'0px',borderRightWidth:'1px',borderColor:'grey'}}>
          <text className="BodyLight" style={{fontSize:'14px',color:'grey',padding:'5px',width:'140px',color:"black",textAlign:'left',marginLeft:'8px'}}>{<NumberFormat value={(l.TotalSpending/100).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />}</text>

      </div>
      <div style={{height:'35px',width:'40px',display:'flex',flexDirection:'row',alignItems:'flex-start',justifyContent:'flex-start',borderStyle:'solid',borderWidth:'0px',borderRightWidth:'0px',borderColor:'grey'}}>
          <text className="BodyLight" style={{fontSize:'14px',color:'black',padding:'5px',width:'40px',textAlign:'left',marginLeft:'8px'}}>{l.PurchaseCount}</text>

      </div>


    </div>



    </div>

  ))}

    </div>

    </div>


   );
  }
 }

export default DashboardSortByUsers;
