import React, { Component } from 'react';
import './App.css';

class BuyButtonDesktop extends Component {

  state = {
    mouseOverCompletePaymentButton: false,
    mouseOverChangePaymentMethodButton:false,
    walletSelectionIndex:0,
    mouseOverSendToNiftyGatewayWalletButton: false,
    mouseOverSendToMetaMaskWalletButton: false,
  }

  render() {
    return (



      <div style={{background: 'linear-gradient(128deg, #00F5C3 0%, #00E0EE 100%)',zIndex:0,display:'flex',flexDirection:'column',alignItems:'center',justifyContent:"flex-start",height:"100vh",minHeight:'700px',width:'100%',overflow:"scroll"}}>

      <div style={{backgroundColor:'white',width:'340px',height:'670px',borderRadius:'5px',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'flex-start',marginTop:'20px'}}>

          <div style={{width:"86%",borderStyle:'solid',borderWidth:'0px',borderColor:'#d6d6d6',borderBottomWidth:'1px',height:'112px',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'flex-end'}}>


                <img src={'https://s3.amazonaws.com/matterhornphotostorage/FaviconNFTG.png'} style={{width:'45px',height:'45px',marginBottom:'5px',marginTop:'10px'}} />
                <text className="bodyNormal" style={{fontSize:'22px',marginBottom:'10px'}}>Buy with Nifty Gateway</text>


          </div>

          <div style={{width:"86%",borderStyle:'solid',borderWidth:'0px',borderColor:'#d6d6d6',borderBottomWidth:'1px',height:'165px',display:'flex',flexDirection:'column',alignItems:'flex-start',justifyContent:'flex-start'}}>

                    <div style={{width:"300px",borderStyle:'solid',borderWidth:'0px',borderColor:'#d6d6d6',borderBottomWidth:'0px',height:'165px',display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center',flex:1}}>

                        <div style={{flex:.49,width:'140px',borderStyle:'solid',borderWidth:'0px',borderColor:'#d6d6d6',borderBottomWidth:'0px',height:'142px',display:'flex',flexDirection:'column',alignItems:'flex-start',justifyContent:'flex-start'}}>


                              <text className="bodyNormal" style={{fontSize:'14.3px',marginTop:'5px'}}>Buying:</text>

                              <div style={{width:'100%',display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center',marginTop:'10px',whiteSpace:"no-wrap",overflow:"hidden",textOverflow:"ellipsis"}}>

                                  <img src={'https://s3.amazonaws.com/matterhornphotostorage/95792.png'} style={{width:'54px',height:'54px',borderRadius:'3px'}} />
                                  <text className="bodyNormal" style={{fontSize:'12.5px',marginLeft:'5px',width:'80px',height:'51px',whiteSpace:"no-wrap",overflow:"hidden",textOverflow:"ellipsis",paddingRight:'2px',alignSelf:"center",display:'flex',flexDirection:'column',justifyContent:'center'}}>Yasil Puig</text>

                              </div>

                              <text className="bodyNormal" style={{fontSize:'12.5px',marginTop:'10px'}}>From:</text>
                              <text className="bodyNormal" style={{fontSize:'12.5px',marginTop:'5px',height:'34px',display:'flex',flexDirection:'column',justifyContent:"center",overflow:"hidden",textOverflow:"ellipsis",}}>MLB Crypto Baseball</text>

                        </div>

                        <div style={{flex:.01,height:'100px',width:'0px',borderStyle:'solid',borderWidth:'0px',borderColor:'#dedede',borderLeftWidth:'1px'}}></div>

                        <div style={{flex:.49,width:'140px',paddingLeft:'15px',borderStyle:'solid',borderWidth:'0px',borderColor:'#d6d6d6',borderBottomWidth:'0px',height:'142px',display:'flex',flexDirection:'column',alignItems:'flex-start',justifyContent:'flex-start'}}>

                          <text className="bodyNormal" style={{fontSize:'14.3px',marginTop:'5px'}}>Cost:</text>

                          <text className="bodyNormal" style={{fontSize:'12.5px',marginTop:'20px',color:'#BBBBBB'}}>Subtotal: $23.00</text>
                          <text className="bodyNormal" style={{fontSize:'12.5px',marginTop:'10px',color:'#BBBBBB'}}>Transaction Fee: $1.87</text>

                          <text className="bodyNormal" style={{fontSize:'12.5px',marginTop:'28.5px'}}>You'll Pay: <text style={{fontSize:'16.5px'}}>$24.87</text></text>

                        </div>


                      </div>


          </div>


          <div style={{width:"86%",borderStyle:'solid',borderWidth:'0px',borderColor:'#d6d6d6',borderBottomWidth:'1px',height:'166px',display:'flex',flexDirection:'column',alignItems:'flex-start',justifyContent:'flex-start'}}>

              <text className="bodyNormal" style={{fontSize:'14.3px',marginTop:'10px'}}>Payment Method:</text>

              <div style={{width:'100%',marginTop:'15px',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>

                <text className="bodyNormal" style={{fontSize:'9px',color:'#cecece'}}>Default Payment Method</text>
                <div style={{width:'100%',marginTop:'2px',display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'flex-start',borderStyle:'solid',borderColor:'#dedede',borderWidth:'1px',borderRadius:'5px',height:'45px'}}>
                <img src={'https://www2.bac-assets.com/homepage/spa-assets/images/assets-images-global-logos-boa-logo-CSXe4b047c0.svg'} style={{objectFit:'contain',height:'25px',width:'130px',marginLeft:'15px'}} />
                <text style={{marginLeft:'7.5px',fontSize:'13px',color:'#999'}}>Acct ending in 6242</text>
                </div>
              </div>

              <div onMouseOver={()=>{this.setState({mouseOverChangePaymentMethodButton:true})}} onMouseLeave={()=>{this.setState({mouseOverChangePaymentMethodButton:false})}}
              style={{cursor:"pointer",backgroundColor:this.state.mouseOverChangePaymentMethodButton?'#eee':'white',width:'50%',marginTop:'10px',display:'flex',flexDirection:'column',alignSelf:"center",alignItems:'center',justifyContent:'center',borderStyle:'solid',borderColor:'#dedede',borderWidth:this.state.mouseOverChangePaymentMethodButton?'0px':'1px',borderRadius:'0px',height:'25px',marginTop:'10px'}}>

              <text style={{fontSize:'10px',color:this.state.mouseOverChangePaymentMethodButton?'white':'black'}}>Change Payment Method</text>
              </div>

          </div>

          <div style={{width:"86%",borderStyle:'solid',borderWidth:'0px',borderColor:'#d6d6d6',borderBottomWidth:'1px',height:'181px',display:'flex',flexDirection:'column',alignItems:'flex-start',justifyContent:'flex-start'}}>

              <text className="bodyNormal" style={{fontSize:'14.3px',marginTop:'10px'}}>Destination:</text>


                {this.state.walletSelectionIndex===0?
                  <div
                  style={{width:'100%',marginTop:'7.5px',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
                  <img onClick={()=>{this.setState({walletSelectionIndex:1,mouseOverSendToNiftyGatewayWalletButton:false})}}
                  src={'https://s3.amazonaws.com/matterhornphotostorage/FaviconNFTG.png'} style={{cursor:'pointer',alignSelf:"center",width:'40px',height:'40px',marginBottom:'5px'}} />
                  <text
                  onClick={()=>{this.setState({walletSelectionIndex:1,mouseOverSendToNiftyGatewayWalletButton:false})}}
                  className="bodyBold" style={{cursor:'pointer',fontSize:'19px',color:'black',alignSelf:'center'}}>Your Nifty Gateway Wallet</text>

                      <div onClick={()=>{this.setState({walletSelectionIndex:1,mouseOverSendToNiftyGatewayWalletButton:false})}}
                      onMouseOver={()=>{this.setState({mouseOverSendToMetaMaskWalletButton:true})}} onMouseLeave={()=>{this.setState({mouseOverSendToMetaMaskWalletButton:false})}}
                       style={{cursor:'pointer',width:'80%',marginTop:'10px',display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center',borderStyle:'solid',borderColor:'#eee',borderWidth:'0px',borderTopWidth:'1px'}}>

                          <img src={'https://s3.amazonaws.com/matterhornphotostorage/GreyedOutMetamaskLogo.png'} style={{alignSelf:"center",width:'20px',height:'20px',marginTop:'10px',opacity:this.state.mouseOverSendToMetaMaskWalletButton?'.3':'1'}} />
                          <text className="bodyNormal" style={{marginLeft:'5px',fontSize:'15px',color:this.state.mouseOverSendToMetaMaskWalletButton?'#eee':'#cecece',marginTop:'10px',alignSelf:'center'}}>Send to your Metamask Wallet</text>
                      </div>
                  </div>

                  :
                  <div style={{width:'100%',marginTop:'7.5px',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
                  <img
                  onClick={()=>{this.setState({walletSelectionIndex:0,mouseOverSendToMetaMaskWalletButton:false})}}
                  src={'https://s3.amazonaws.com/matterhornphotostorage/NotGreyedOutMetamaskLogo.png'} style={{cursor:'pointer',alignSelf:"center",width:'40px',height:'40px',marginBottom:'5px'}} />
                  <text
                  onClick={()=>{this.setState({walletSelectionIndex:0,mouseOverSendToMetaMaskWalletButton:false})}}
                  className="bodyBold" style={{cursor:'pointer',fontSize:'19px',color:'black',alignSelf:'center'}}>Your Metamask Wallet</text>

                      <div onClick={()=>{this.setState({walletSelectionIndex:0,mouseOverSendToMetaMaskWalletButton:false})}}
                      onMouseOver={()=>{this.setState({mouseOverSendToNiftyGatewayWalletButton:true})}} onMouseLeave={()=>{this.setState({mouseOverSendToNiftyGatewayWalletButton:false})}}
                       style={{cursor:'pointer',width:'80%',marginTop:'10px',display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center',borderStyle:'solid',borderColor:'#eee',borderWidth:'0px',borderTopWidth:'1px'}}>

                          <img src={'https://s3.amazonaws.com/matterhornphotostorage/GreyedOutNiftyGatewayLogo.png'} style={{alignSelf:"center",width:'20px',height:'20px',marginTop:'10px',opacity:this.state.mouseOverSendToNiftyGatewayWalletButton?'.3':'1'}} />
                          <text className="bodyNormal" style={{marginLeft:'5px',fontSize:'13px',color:this.state.mouseOverSendToNiftyGatewayWalletButton?'#eee':'#cecece',marginTop:'10px',alignSelf:'center'}}>Send to your Nifty Gateway Wallet</text>
                      </div>
                  </div>

                }



          </div>


          <div style={{width:'293px',height:'86px',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>


            <div className="completePaymentButton" onMouseOver={()=>{this.setState({mouseOverCompletePaymentButton:true})}} onMouseLeave={()=>{this.setState({mouseOverCompletePaymentButton:false})}}
            style={{background:this.state.mouseOverCompletePaymentButton?'linear-gradient(-128deg, #00F5C3 0%, #00E0EE 100%)':null,backgroundColor:this.state.mouseOverCompletePaymentButton?'white':null,width:'100%',height:'58px',borderRadius:'10px',borderStyle:'solid',borderColor:this.state.mouseOverCompletePaymentButton?'white':"#e4e4e4",borderWidth:'1px',
            display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center',cursor:'pointer'}}>

              <text className={this.state.mouseOverCompletePaymentButton?"bodyNormal":"bodyNormal"} style={{fontSize:'17px',color:this.state.mouseOverCompletePaymentButton?'white':'black'}}>Confirm Payment - $24.87</text>


            </div>


          </div>






      </div>

      <div style={{height:'5px',marginTop:'5px',width:'340px',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'flex-start',}}>

        <text className='bodyNormal' style={{fontSize:'13px',color:"white"}}>Questions? Reach out to support@niftygateway.com</text>


      </div>


      </div>


    );
  }
}

export default BuyButtonDesktop;
