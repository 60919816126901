import React, { Component } from 'react';
import './App.css';

class BuyButtonPreLogin extends Component {

    state = {
      mouseOverCompletePaymentButton: false,
      mouseOverChangePaymentMethodButton:false,
      walletSelectionIndex:0,
      mouseOverSendToNiftyGatewayWalletButton: false,
      mouseOverSendToMetaMaskWalletButton: false,
      mouseOverLogInButton: false,
      mouseOverCreateAccountButton: false,
    }

    render() {
      return (



        <div style={{background: 'linear-gradient(128deg, #00F5C3 0%, #00E0EE 100%)',zIndex:0,display:'flex',flexDirection:'column',alignItems:'center',justifyContent:"flex-start",height:"100vh",minHeight:'700px',width:'100%',overflow:"scroll"}}>

        <div style={{backgroundColor:'white',width:'340px',height:'670px',borderRadius:'5px',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'flex-start',marginTop:'20px'}}>

            <div style={{opacity:'.4',width:"86%",borderStyle:'solid',borderWidth:'0px',borderColor:'#d6d6d6',borderBottomWidth:'1px',height:'112px',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'flex-end'}}>


                  <img src={'https://s3.amazonaws.com/matterhornphotostorage/FaviconNFTG.png'} style={{width:'45px',height:'45px',marginBottom:'5px',marginTop:'10px'}} />
                  <text className="bodyNormal" style={{fontSize:'22px',marginBottom:'10px'}}>Buy with Nifty Gateway</text>


            </div>




            <div style={{width:"86%",borderStyle:'solid',borderWidth:'0px',borderColor:'#d6d6d6',borderLeftWidth:'0px',borderRightWidth:'0px',borderBottomWidth:'1px',height:'512px',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>

                    <text className="bodyNormal" style={{fontSize:'26px',color:'black',textAlign:'center',paddingLeft:'20px',paddingRight:'20px'}}>Log in or Create Account to continue</text>

              <div style={{borderStyle:'solid',borderWidth:'0px',borderBottomWidth:'1px',borderColor:'#dedede',height:'.25px',width:'150px',marginTop:'30px',marginBottom:'30px'}} />


                    <text className="bodyNormal" style={{fontSize:'13px',color:'grey',marginTop:'0px',marginBottom:'2px',alignSelf:'flex-start',marginLeft:'40px'}}>Email</text>
                    <input className="bodyNormal" type="email" name="email" style={{paddingLeft:'5px',height:'30px',width:'220px',borderStyle:'solid',borderWidth:'1px',borderColor:'#dedede',borderRadius:'4px',fontSize:'14px'}} />

                    <text className="bodyNormal" style={{fontSize:'13px',color:'grey',marginTop:'5px',marginBottom:'2px',alignSelf:'flex-start',marginLeft:'40px'}}>Password</text>
                    <input className="bodyNormal" type="password" name="password" style={{paddingLeft:'5px',height:'30px',width:'220px',borderStyle:'solid',borderWidth:'1px',borderColor:'#dedede',borderRadius:'4px',fontSize:'14px'}} />

              <div style={{borderStyle:'solid',borderWidth:'0px',borderBottomWidth:'1px',borderColor:'#dedede',height:'.25px',width:'150px',marginTop:'30px',marginBottom:'30px'}} />

                    <div className="LogInButton" onMouseOver={()=>{this.setState({mouseOverLogInButton:true})}} onMouseLeave={()=>{this.setState({mouseOverLogInButton:false})}}
                    style={{background:this.state.mouseOverLogInButton?'linear-gradient(-128deg, #00F5C3 0%, #00E0EE 100%)':null,backgroundColor:this.state.mouseOverLogInButton?'white':null,width:'220px',height:'40px',borderRadius:'8px',borderStyle:'solid',borderColor:this.state.mouseOverLogInButton?'white':"#e4e4e4",borderWidth:'1px',
                    display:'flex',marginTop:'0px',flexDirection:'column',alignItems:'center',justifyContent:'center',cursor:'pointer'}}>

                      <text className="bodyNormal" style={{fontSize:'15px',color:this.state.mouseOverLogInButton?'white':'black'}}>Log In</text>

                    </div>

                    <div className="LogInButton" onMouseOver={()=>{this.setState({mouseOverCreateAccountButton:true})}} onMouseLeave={()=>{this.setState({mouseOverCreateAccountButton:false})}}
                    style={{background:this.state.mouseOverCreateAccountButton?'linear-gradient(-128deg, #00F5C3 0%, #00E0EE 100%)':null,backgroundColor:this.state.mouseOverCreateAccountButton?'white':null,width:'220px',height:'40px',borderRadius:'8px',borderStyle:'solid',borderColor:this.state.mouseOverCreateAccountButton?'white':"#e4e4e4",borderWidth:'1px',
                    display:'flex',marginTop:'8px',flexDirection:'column',alignItems:'center',justifyContent:'center',cursor:'pointer'}}>

                      <text className="bodyNormal" style={{fontSize:'15px',color:this.state.mouseOverCreateAccountButton?'white':'black'}}>Create Account</text>

                    </div>

            </div>


            <div style={{opacity:'.4',width:'293px',height:'86px',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>


              <div className="completePaymentButton" onMouseOver={()=>{this.setState({mouseOverCompletePaymentButton:true})}} onMouseLeave={()=>{this.setState({mouseOverCompletePaymentButton:false})}}
              style={{background:this.state.mouseOverCompletePaymentButton?'linear-gradient(-128deg, #00F5C3 0%, #00E0EE 100%)':null,backgroundColor:this.state.mouseOverCompletePaymentButton?'white':null,width:'100%',height:'58px',borderRadius:'10px',borderStyle:'solid',borderColor:this.state.mouseOverCompletePaymentButton?'white':"#e4e4e4",borderWidth:'1px',
              display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center',cursor:'pointer'}}>

                <text className={this.state.mouseOverCompletePaymentButton?"bodyNormal":"bodyNormal"} style={{fontSize:'17px',color:this.state.mouseOverCompletePaymentButton?'white':'black'}}>Confirm Payment - $24.87</text>



              </div>


            </div>






        </div>

        <div style={{height:'5px',marginTop:'5px',width:'340px',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'flex-start',}}>

          <text className='bodyNormal' style={{fontSize:'13px',color:"white"}}>Questions? Reach out to support@niftygateway.com</text>


        </div>


        </div>


      );
    }
  }

export default BuyButtonPreLogin;
