import React, { Component } from 'react';
import './App.css';
import axios from 'axios';
import NumberFormat from 'react-number-format';

import Sidebar from './Sidebar.js';
import {NavLink} from 'react-router-dom';

import { Chart } from "react-charts";

import {returnWeeksList,returnWeeksListLimitedDatesOneDayAhead} from './weeksList';

const weeksList = returnWeeksListLimitedDatesOneDayAhead(86400000,(new Date()).getTime()-7862400000);

const expandedMonthsArray = ['01','02','03','04','05','06','07','08','09','10','11','12'];

class DashboardDodPast90 extends Component {

  constructor(){
    super();
    this.state={

      DailyTransactionsArray: [],
      DailyTransactionsArryForGraph: [],
      DailyWoWGraph: [],
      Daily4DayAverageGraph: [],
      revenueArrayForGraph: [],
      weeksList: weeksList,
      fourWeekRevGraph: [],
    }
  }

   componentDidMount(){
    var currentDate = new Date('03/22/19');
    console.log(currentDate,'currentDate');
    var newWeeksList = [];
     for(var k=0;k<this.state.weeksList.length;k++){
      console.log(weeksList[k].End,'weeksList[k]')
      var weeksListDate = new Date(weeksList[k].Start);
      console.log(weeksListDate,'weeksListDate');
      if(weeksListDate.getTime()<currentDate.getTime()){
        console.log(weeksList[k].Start + ' to ' + weeksList[k].End,'this is the date range that should apply');
        newWeeksList.push(weeksList[k]);
      }
    }
    this.setState({weeksList:newWeeksList});
  }

  async componentWillMount(){
    var DailyTransactionsArray = [];
    for(var i=0;i<weeksList.length;i++){
     var thisWeeksData = {Start:weeksList[i].Start,End:weeksList[i].End};
     var url = 'https://api.niftygateway.com/dashboard/date-range-co/?start_date='+weeksList[i].Start+'&end_date='+weeksList[i].End;
     await axios.get(url).then(res => {

       thisWeeksData.data = res.data;
       DailyTransactionsArray.push(thisWeeksData);
     }).catch(error => {
       console.log(error,'error response')
       throw error;
     });

   }
   //console.log(DailyTransactionsArray,'DailyTransactionsArray');


   for(var i=0;i<weeksList.length;i++){
  //    console.log(DailyTransactionsArray,'DailyTransactionsArray logged many times')
      var woW = 'N/A';
      var fourWeekAvg = 'N/A';
      var DailyRevenue = 0;


      if(i<weeksList.length-1){
        var currentWeek = DailyTransactionsArray[i].data.count;
        var lastWeeksCount = DailyTransactionsArray[i+1].data.count;
        woW = (currentWeek-lastWeeksCount)/lastWeeksCount;
      }

      if(i<weeksList.length-3){
        fourWeekAvg = .25*(DailyTransactionsArray[i].data.count+DailyTransactionsArray[i+1].data.count+DailyTransactionsArray[i+2].data.count+DailyTransactionsArray[i+3].data.count);

      }


      for (var j=0;j<DailyTransactionsArray[i].data.data.length;j++){
        DailyRevenue = DailyRevenue + DailyTransactionsArray[i].data.data[j].TotalAmountToCharge;
      }

    //  console.log(DailyTransactionsArray[i].data.data.length,'DailyTransactionsArray[i].data');

    //  DailyRevenue = DailyTransactionsArray[i].data.data.reduce((partial_sum, a) => partial_sum + a)

      DailyTransactionsArray[i].data.wow = woW;
      DailyTransactionsArray[i].data.fourWeekAvg = fourWeekAvg;
      DailyTransactionsArray[i].data.DailyRevenue = DailyRevenue;
    }

    for(var i=0;i<weeksList.length;i++){
  //     console.log(DailyTransactionsArray,'DailyTransactionsArray logged many times')
       var fourWeekAvgAvg = 'N/A';

       var fourWeekAverageOfWoWGrowth = 'N/A';

        var rev4FourWeekMovingAverage = [];

       if(i<weeksList.length-2){
         var currentWeek = DailyTransactionsArray[i].data.fourWeekAvg;
         var lastWeeksCount = DailyTransactionsArray[i+1].data.fourWeekAvg;
         fourWeekAvgAvg = (currentWeek-lastWeeksCount)/lastWeeksCount;
       }

       if(i<weeksList.length-5){
         fourWeekAverageOfWoWGrowth = .25*(DailyTransactionsArray[i].data.wow+DailyTransactionsArray[i+1].data.wow+DailyTransactionsArray[i+2].data.wow+DailyTransactionsArray[i+3].data.wow);


       }

       if(i<weeksList.length-3){
          rev4FourWeekMovingAverage = .25*(DailyTransactionsArray[i].data.DailyRevenue+DailyTransactionsArray[i+1].data.DailyRevenue+DailyTransactionsArray[i+2].data.DailyRevenue+DailyTransactionsArray[i+3].data.DailyRevenue);
       } else {
         rev4FourWeekMovingAverage = 'N/A'
       }

       DailyTransactionsArray[i].data.fourWeekWoWAvg = fourWeekAvgAvg;
        DailyTransactionsArray[i].data.fourWeekAverageOfWoWGrowth = fourWeekAverageOfWoWGrowth;
        DailyTransactionsArray[i].data.rev4FourWeekMovingAverage = rev4FourWeekMovingAverage;
     }


   var DailyTransactionsArryForGraph = [[0,0]];
   var DailyWoWGraph = [];

   var Daily4DayAverageGraph = [[0,0]];

   var revenueArrayForGraph = [[0,0]];

   var fourWeekRevGraph = [[0,0]];


   var DailyTransactionsArrayForGraph = DailyTransactionsArray;

   DailyTransactionsArrayForGraph.reverse();

   //calculating data for graphs
   for(var i=0;i<weeksList.length;i++){

     var weeksData = [i+1,DailyTransactionsArrayForGraph[i].data.count];
     DailyTransactionsArryForGraph.push(weeksData);

     var weeksDataRev = [i+1,(DailyTransactionsArrayForGraph[i].data.DailyRevenue/100).toFixed(2)];
     revenueArrayForGraph.push(weeksDataRev);

      if(i>0){
       var weeksDataWow = [i,DailyTransactionsArrayForGraph[i].data.wow];
       DailyWoWGraph.push(weeksDataWow);
      }

     if(i>3){
     var weeksDataFourWeek = [i+1,DailyTransactionsArrayForGraph[i].data.fourWeekAvg];
     Daily4DayAverageGraph.push(weeksDataFourWeek);
      }

      if(i>3){
        var weeksDataFourWeekRevGraph = [i-3,(DailyTransactionsArrayForGraph[i].data.rev4FourWeekMovingAverage/100).toFixed(2)];
        fourWeekRevGraph.push(weeksDataFourWeekRevGraph);
      }

   }
//   console.log(DailyWoWGraph,'DailyWoWGraph',Daily4DayAverageGraph,'Daily4DayAverageGraph');
   this.setState({fourWeekRevGraph:fourWeekRevGraph,DailyTransactionsArryForGraph:DailyTransactionsArryForGraph,DailyWoWGraph:DailyWoWGraph,Daily4DayAverageGraph:Daily4DayAverageGraph,revenueArrayForGraph:revenueArrayForGraph,DailyTransactionsArray: DailyTransactionsArray.reverse()});

  }

  render() {
   return (

    <div >

    <div style={{width:'100%',position:'absolute',top:0,height:'120px',display:'flex',flexDirection:"column",alignItems:'center',justifyContent:"center"}}>
    <text className="bodyMedium" style={{fontSize:'55px'}}>Daily Transactions</text>

     </div>
    <Sidebar />

  <div  style={{  position:"absolute",top:150,left:290,width: "300px",height: "200px"}}>
    <Chart
      tooltip={true}
      data={[
        {
          label: "Daily Transactions",
          data: this.state.DailyTransactionsArryForGraph,
        },

      ]}
      axes={[
        { primary: true, type: "linear", position: "bottom" },
        { type: "linear", position: "left" }
      ]}
    />
  </div>

  <div style={{  position:"absolute",top:150,left:590,width: "300px",height: "200px"}}>
  <Chart
  tooltip={true}
    data={[
      {
        label: "Revenue",
        data: this.state.revenueArrayForGraph,
      },

    ]}
    axes={[
      { primary: true, type: "linear", position: "bottom" },
      { type: "linear", position: "left" }
    ]}
  />
  </div>

  <div style={{  position:"absolute",top:150,left:890,width: "300px",height: "200px"}}>
  <Chart
  tooltip={true}
    data={[
      {
        label: "Revenue",
        data: this.state.fourWeekRevGraph,
        color: 'green',
      },

    ]}
    axes={[
      { primary: true, type: "linear", position: "bottom" },
      { type: "linear", position: "left" }
    ]}
  />
  </div>


    <text className="bodyNormal" style={{fontSize:'15.5px',position:'absolute',left:370,top:355}}>Daily Transactions</text>

    <text className="bodyNormal" style={{fontSize:'15.5px',position:'absolute',left:700,top:355}}>Daily GMV</text>

    <text className="bodyNormal" style={{fontSize:'15.5px',position:'absolute',left:970,top:355}}>GMV 4 Day Moving Average</text>


    <text className="bodyNormal" style={{fontSize:'15px',position:'absolute',left:440,top:420}}># of txs </text>

    <text className="bodyNormal" style={{fontSize:'15px',position:'absolute',left:530,top:420}}>WoW Growth</text>

    <text className="bodyNormal" style={{fontSize:'15px',position:'absolute',left:660,top:420}}>4 Day Avg</text>

    <text className="bodyNormal" style={{fontSize:'15px',position:'absolute',left:790,top:420}}>GMV</text>

    <text className="bodyNormal" style={{fontSize:'15px',position:'absolute',left:930,top:420}}>4 Day GM Average</text>

    <div style={{position:'absolute',top:460,left:290,width:'900px',borderStyle:"solid",borderWidth:'0px',borderTopWidth:"1px",borderColor:'lightgrey',height:'1px'}}> </div>

    <div style={{position:'absolute',top:460,left:250,width:'1000px',display:"flex",flexDirection:'column',alignItems:'center',justifyContent:'flex-start',}}>

    {this.state.DailyTransactionsArray.map((l,i) => (

        <NavLink to={"/SpecificDayDashboard/Day/"+new Date(l.Start).getUTCFullYear()+'-'+(expandedMonthsArray[new Date(l.Start).getMonth()])+"-"+new Date(l.Start).getDate()} style={{textDecoration:'none'}}>
     <div className="hoverRow" style={{display:'flex',flexDirection:"row",alignItems:'center',justifyContent:'flex-start',height:'40px',borderStyle:"solid",borderWidth:'0px',borderBottomWidth:"1px",borderColor:'lightgrey',width:'900px'}}>

     <text className="bodyNormal" style={{fontSize:'17px',color:"black",width:'120px'}}>{new Date(l.Start).getMonth()+1}/{new Date(l.Start).getDate()} to {new Date(l.End).getMonth()+1}/{new Date(l.End).getDate()-1}</text>

     <text className="bodyMedium" style={{fontSize:"18px",marginLeft:'40px',width:'80px',color:"black"}}>{l.data.count}</text>
     <text className="bodyMedium" style={{fontSize:"18px",marginLeft:'5px',width:'120px',color:"black"}}>{new Number(l.data.wow).toFixed(2)*100}%</text>
     <text className="bodyMedium" style={{fontSize:"18px",marginLeft:'5px',width:'120px',color:"black"}}>{l.data.fourWeekAvg}</text>
     <text className="bodyMedium" style={{fontSize:"18px",marginLeft:'5px',width:'120px',color:"black"}}>{<NumberFormat value={(l.data.DailyRevenue/100).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />}</text>
     <text className="bodyMedium" style={{fontSize:"18px",marginLeft:'5px',width:'120px',color:"black"}}>{<NumberFormat value={(l.data.rev4FourWeekMovingAverage/100).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />}</text>



     </div>
        </NavLink>
    ))}

    <div style={{height:'120px'}}></div>

    </div>

    </div>


   );
  }
 }

export default DashboardDodPast90;
