import React, { Component } from 'react';
import './App.css';
import axios from 'axios';
import NumberFormat from 'react-number-format';


import {returnMonthsList} from './monthsList';

import Sidebar from './Sidebar.js';

import { Chart } from "react-charts";

const weeksList = returnMonthsList();

class Dashboard extends Component {

  constructor(){
    super();
    this.state={
      weeksList: weeksList,
      MonthlyTransactionsArray: [],
      MonthlyTransactionsArryForGraph: [],
      MonthlyWoWGraph: [],
      Monthly4WkAverageGraph: [],
      revenueArrayForGraph: [],
    }
  }




  async componentWillMount(){
    var MonthlyTransactionsArray = [];
    for(var i=0;i<weeksList.length;i++){
     var thisWeeksData = {Start:weeksList[i].Start,End:weeksList[i].End};
     var url = 'https://api.niftygateway.com/dashboard/date-range-co/?start_date='+weeksList[i].Start+'&end_date='+weeksList[i].End;
     await axios.get(url).then(res => {

       thisWeeksData.data = res.data;
       MonthlyTransactionsArray.push(thisWeeksData);
     }).catch(error => {
       console.log(error,'error response')
       throw error;
     });

   }
   //console.log(MonthlyTransactionsArray,'MonthlyTransactionsArray');

   for(var i=0;i<weeksList.length;i++){
  //    console.log(MonthlyTransactionsArray,'MonthlyTransactionsArray logged many times')
      var woW = 'N/A';
      var fourWeekAvg = 'N/A';
      var MonthlyRevenue = 0;

      if(i<weeksList.length-1){
        var currentWeek = MonthlyTransactionsArray[i].data.count;
        var lastWeeksCount = MonthlyTransactionsArray[i+1].data.count;
        woW = (currentWeek-lastWeeksCount)/lastWeeksCount;
      }

      if(i<weeksList.length-3){
        fourWeekAvg = .25*(MonthlyTransactionsArray[i].data.count+MonthlyTransactionsArray[i+1].data.count+MonthlyTransactionsArray[i+2].data.count+MonthlyTransactionsArray[i+3].data.count);
      }


      for (var j=0;j<MonthlyTransactionsArray[i].data.data.length;j++){
        MonthlyRevenue = MonthlyRevenue + MonthlyTransactionsArray[i].data.data[j].TotalAmountToCharge;
      }

    //  console.log(MonthlyTransactionsArray[i].data.data.length,'MonthlyTransactionsArray[i].data');

    //  MonthlyRevenue = MonthlyTransactionsArray[i].data.data.reduce((partial_sum, a) => partial_sum + a)

      MonthlyTransactionsArray[i].data.wow = woW;
      MonthlyTransactionsArray[i].data.fourWeekAvg = fourWeekAvg;
      MonthlyTransactionsArray[i].data.MonthlyRevenue = MonthlyRevenue;
    }

    for(var i=0;i<weeksList.length;i++){
  //     console.log(MonthlyTransactionsArray,'MonthlyTransactionsArray logged many times')
       var fourWeekAvgAvg = 'N/A';
       var fourWeekAverageOfWoWGrowth = 'N/A';

       if(i<weeksList.length-2){
         var currentWeek = MonthlyTransactionsArray[i].data.fourWeekAvg;
         var lastWeeksCount = MonthlyTransactionsArray[i+1].data.fourWeekAvg;
         fourWeekAvgAvg = (currentWeek-lastWeeksCount)/lastWeeksCount;
       }

       if(i<weeksList.length-5){
         fourWeekAverageOfWoWGrowth = .25*(MonthlyTransactionsArray[i].data.wow+MonthlyTransactionsArray[i+1].data.wow+MonthlyTransactionsArray[i+2].data.wow+MonthlyTransactionsArray[i+3].data.wow);
       }

       MonthlyTransactionsArray[i].data.fourWeekWoWAvg = fourWeekAvgAvg;
        MonthlyTransactionsArray[i].data.fourWeekAverageOfWoWGrowth = fourWeekAverageOfWoWGrowth;
     }




   var MonthlyTransactionsArryForGraph = [[0,0]];
   var MonthlyWoWGraph = [];
   var Monthly4WkAverageGraph = [];
   var revenueArrayForGraph = [[0,0]];

   var MonthlyTransactionsArrayForGraph = MonthlyTransactionsArray;

   MonthlyTransactionsArrayForGraph.reverse();

   for(var i=0;i<weeksList.length;i++){

     var weeksData = [i+1,MonthlyTransactionsArrayForGraph[i].data.count];
     MonthlyTransactionsArryForGraph.push(weeksData);

     var weeksDataRev = [i+1,(MonthlyTransactionsArrayForGraph[i].data.MonthlyRevenue/100).toFixed(2)];
     revenueArrayForGraph.push(weeksDataRev);

      if(i>0){
       var weeksDataWow = [i,MonthlyTransactionsArrayForGraph[i].data.wow];
       MonthlyWoWGraph.push(weeksDataWow);
      }

     if(i>2){
     var weeksDataFourWeek = [i,MonthlyTransactionsArrayForGraph[i].data.fourWeekAvg];
     Monthly4WkAverageGraph.push(weeksDataFourWeek);
      }

   }
//   console.log(MonthlyWoWGraph,'MonthlyWoWGraph',Monthly4WkAverageGraph,'Monthly4WkAverageGraph');
   this.setState({MonthlyTransactionsArryForGraph:MonthlyTransactionsArryForGraph,MonthlyWoWGraph:MonthlyWoWGraph,Monthly4WkAverageGraph:Monthly4WkAverageGraph,revenueArrayForGraph:revenueArrayForGraph,MonthlyTransactionsArray: MonthlyTransactionsArray.reverse()});

  }

  render() {
   return (

    <div >

    <div style={{width:'100%',position:'absolute',top:0,height:'120px',display:'flex',flexDirection:"column",alignItems:'center',justifyContent:"center"}}>
    <text className="bodyMedium" style={{fontSize:'55px'}}>Monthly Transactions</text>

     </div>
    <Sidebar />

  <div  style={{  position:"absolute",top:150,left:320,width: "300px",height: "200px"}}>
    <Chart
    tooltip={true}
      data={[
        {
          label: "Monthly Transactions",
          data: this.state.MonthlyTransactionsArryForGraph,
        },

      ]}
      axes={[
        { primary: true, type: "linear", position: "bottom" },
        { type: "linear", position: "left" }
      ]}
    />
  </div>

  <div style={{  position:"absolute",top:150,left:720,width: "300px",height: "200px"}}>
  <Chart
    tooltip={true}
    data={[
      {
        label: "Monthly Revenue",
        data: this.state.revenueArrayForGraph,
      },

    ]}
    axes={[
      { primary: true, type: "linear", position: "bottom" },
      { type: "linear", position: "left" }
    ]}
  />
  </div>


    <text className="bodyNormal" style={{fontSize:'15.5px',position:'absolute',left:400,top:355}}>Monthly Transactions</text>

    <text className="bodyNormal" style={{fontSize:'15.5px',position:'absolute',left:840,top:355}}>Monthly Revenue</text>


    <text className="bodyNormal" style={{fontSize:'15px',position:'absolute',left:440,top:420}}># of txs </text>

    <text className="bodyNormal" style={{fontSize:'15px',position:'absolute',left:530,top:420}}>MoM Growth</text>

    <text className="bodyNormal" style={{fontSize:'15px',position:'absolute',left:660,top:420}}>4 Wk Avg</text>

    <text className="bodyNormal" style={{fontSize:'15px',position:'absolute',left:790,top:420}}>Revenue</text>

    <div style={{position:'absolute',top:460,left:290,width:'900px',borderStyle:"solid",borderWidth:'0px',borderTopWidth:"1px",borderColor:'lightgrey',height:'1px'}}> </div>

    <div style={{position:'absolute',top:460,left:250,width:'1000px',display:"flex",flexDirection:'column',alignItems:'center',justifyContent:'flex-start',}}>

    {this.state.MonthlyTransactionsArray.map((l,i) => (
     <div style={{display:'flex',flexDirection:"row",alignItems:'center',justifyContent:'flex-start',height:'40px',borderStyle:"solid",borderWidth:'0px',borderBottomWidth:"1px",borderColor:'lightgrey',width:'900px'}}>
     <text className="bodyNormal" style={{fontSize:'17px',width:'120px'}}>{new Date(l.Start).getMonth()+1}/{new Date(l.Start).getDate()} to {new Date(l.End).getMonth()+1}/{new Date(l.End).getDate()-1} </text>
     <text className="bodyMedium" style={{fontSize:"18px",marginLeft:'40px',width:'80px'}}>{l.data.count}</text>
     <text className="bodyMedium" style={{fontSize:"18px",marginLeft:'5px',width:'120px'}}>{new Number(l.data.wow).toFixed(2)*100}%</text>
     <text className="bodyMedium" style={{fontSize:"18px",marginLeft:'5px',width:'120px'}}>{l.data.fourWeekAvg}</text>
     <text className="bodyMedium" style={{fontSize:"18px",marginLeft:'5px',width:'120px'}}>{<NumberFormat value={(l.data.MonthlyRevenue/100).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />}</text>


     </div>
    ))}

    </div>

    </div>


   );
  }
 }

export default Dashboard;
