import React, { Component } from 'react';
import './App.css';
import axios from 'axios';

import Sidebar from './Sidebar.js';
import NumberFormat from 'react-number-format';

import {returnWeeksList} from './weeksList.js';

const weeksList = returnWeeksList(604800000);


class DashboardInvidualUser extends Component {

  constructor(){
    super();
    var week = window.location.href.substr(-1);
    this.state={
      weeksList: weeksList,
      thisWeeksData: {Start:'',End:'',data:{data:[]}},
      week: week,
      userTransactionsArray: [],
    }
  }


    //console.log(resultArray);




  async componentWillMount(){

    var weeklyTransactionsArray = [];
    var week = window.location.href.substr(-1);
  //  console.log( window.location.href,' window.location.href')
    var newURLCut = 'hi';
    var newURLCut = window.location.href.substr(window.location.href.indexOf('/Dashboard/user/'),window.location.href.length);
    var userEmail = newURLCut.substr(16,100);
    this.setState({userEmail:userEmail});
    // console.log(userEmail,'userEmail')
  //  var newURLCut = window.location.href.substr(window.location.href.indexOf('/Dashboard/user/').window.location.href.length);
    //console.log(newURLCut,'newURLCut');
  //  var emailAddressLength = window.location.href.length-window.location.href.indexOf('/Dashboard/user/')-;
    var i = 2;
    // console.log(i,'logging i');

     var thisWeeksData = {Start:weeksList[i].Start,End:weeksList[i].End};
  //   console.log(weeksList[i].Start,'weeksList[i].Start',weeksList[i].End,'weeksList[i].End');
     var url = 'https://api.niftygateway.com/dashboard/date-range-co/?start_date=01/01/1970&end_date=01/01/2070';
    // console.log(url,'logging url');
     await axios.get(url).then(res => {
    //   console.log(res,'logging res from date range number'+i);
       thisWeeksData.data = res.data;
       // var newArray = res.data.data.reverse();
       // thisWeeksData.data.data = newArray;
       this.setState({thisWeeksData:thisWeeksData});
    //    weeklyTransactionsArray.push(thisWeeksData);
    //   console.log(thisWeeksData,'thisWeeksData')

      // this.setState({pa  ymentSourcesArray: res.data,selectingPage:3,selectedPaymentIndex:newCardIndex,paymentSourcesLoading:false,isCreditCardBeingAdded:false});
     }).catch(error => {
    //   console.log(error.response,'error response')
       throw error;
     });


    // console.log(weeklyTransactionsArray,'weeklyTransactionsArray');

  // console.log(weeklyTransactionsArray,'weeklyTransactionsArray');
  // this.setState({weeklyTransactionsArray: weeklyTransactionsArray});
  // console.log(weeklyTransactionsArray,'weeklyTransactionsArray');

  // console.log(thisWeeksData,'thisWeeksData logged here')
  var userTransactionsArray = [];
  for(var q=0;q<thisWeeksData.data.data.length;q++){
  //  console.log('hi')
    if(thisWeeksData.data.data[q].UserSendingTransaction.email===userEmail){
      userTransactionsArray.push(thisWeeksData.data.data[q]);
      // console.log('hi')
    }
    //if(thisWeeksData[q].data.)
  //  console.log(thisWeeksData.data.data,'logging this weeks data');
  }
  this.setState({userTransactionsArray:userTransactionsArray});
  // console.log(userTransactionsArray,'userTransactionsArray')


  var numOfAxie = 0;
  var numOfGU = 0;
  var numOfOther = 0;

  var sumTotal = 0;

  for (var i=0;i<userTransactionsArray.length;i++){
    console.log(userTransactionsArray[i].TotalAmountToCharge,'userTransactionsArray[i]')
    if(userTransactionsArray[i].AppForPurchaseObject==='AxieInfinityLand'){
      numOfAxie++;
    } else if (userTransactionsArray[i].AppForPurchaseObject==='GodsUnchained'){
      numOfGU++;
    } else {
      numOfOther++;
    }

    sumTotal = sumTotal + Number(userTransactionsArray[i].TotalAmountToCharge);
    console.log(sumTotal,'sumTotal')
  }

  var percentages = {Axie:numOfAxie/(userTransactionsArray.length),GodsUnchained:(numOfGU/userTransactionsArray.length),Other:(numOfOther/userTransactionsArray.length)};

  this.setState({totalWeeklyRev:sumTotal,percentageAxie:percentages.Axie,percentageGU:percentages.GodsUnchained,percentageOther:percentages.Other});

  }



  fetchDataForATimePeriod(startDate,endDate){
    axios.get()
  }


  render() {
   return (

    <div >

    <div style={{width:'100%',position:'absolute',top:0,height:'120px',display:'flex',flexDirection:"column",alignItems:'center',justifyContent:"center"}}>
    <text className="bodyMedium" style={{fontSize:'55px'}}>User Page</text>

     </div>
     <Sidebar />

     <div style={{position:"absolute",top:150,left:650,display:'flex',flexDirection:'column',alignItems:'flex-start',justifyContent:'flex-start'}}>
         <text className="BodyNormal" style={{fontSize:'13px'}}>Axie Infinity: {Number(100*this.state.percentageAxie).toFixed(2)} </text>
         <text className="BodyNormal" style={{fontSize:'13px',marginTop:'15px'}}>Gods Unchained: {Number(100*this.state.percentageGU).toFixed(2)} </text>
         <text className="BodyNormal" style={{fontSize:'13px',marginTop:'15px'}}>Other: {Number(100*this.state.percentageOther).toFixed(2)} </text>
     </div>

      <text className="BodyNormal" style={{fontSize:'18px',position:'absolute',top:200,left:1050}}>Total: {<NumberFormat value={(this.state.totalWeeklyRev/100).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />}</text>

     <text className="BodyNormal" style={{fontSize:'20px',position:'absolute',top:180,left:350}}>{this.state.userEmail}</text>

    <div style={{position:'absolute',top:240,left:290,width:'900px',borderStyle:"solid",borderWidth:'0px',borderTopWidth:"1px",borderColor:'lightgrey',height:'1px'}}> </div>

    <div style={{position:'absolute',top:270,left:250,width:'1000px',display:"flex",flexDirection:'column',alignItems:'center',justifyContent:'flex-start',}}>

    {this.state.userTransactionsArray.map((l,i) => (
    <div style={{width:'850px',display:"flex",flexDirection:"row",alignItems:'flex-start',justifyContent:'flex-start'}}>
    <div style={{width:'900px',borderStyle:'solid',borderWidth:'1px',borderBottomWidth:i+1===this.state.thisWeeksData.data.data.length?'1px':'0px',borderColor:'grey',height:'35px',display:'flex',flexDirection:'row',alignItems:'flex-start',justifyContent:'flex-start'}}>
      <div style={{height:'35px',width:'50px',display:'flex',flexDirection:'row',alignItems:'flex-start',justifyContent:'flex-start',borderStyle:'solid',borderWidth:'0px',borderRightWidth:'1px',borderColor:'grey'}}>
        <text className="BodyLight" style={{fontSize:'17px',color:'grey',padding:'5px',width:'50px',textAlign:'left',marginLeft:'8px'}}>{i+1}</text>

      </div>
      <div style={{height:'35px',width:'215px',display:'flex',flexDirection:'row',alignItems:'flex-start',justifyContent:'flex-start',borderStyle:'solid',borderWidth:'0px',borderRightWidth:'1px',borderColor:'grey'}}>
          <text className="BodyLight" style={{fontSize:'14px',color:'grey',padding:'5px',width:'215px',color:"black",textAlign:'left',marginLeft:'8px'}}>{new Date(l.Timestamp).toLocaleDateString() + " " + new Date(l.Timestamp).toLocaleTimeString()}</text>

      </div>
      <div style={{height:'35px',width:'255px',display:'flex',flexDirection:'row',alignItems:'flex-start',justifyContent:'flex-start',borderStyle:'solid',borderWidth:'0px',borderRightWidth:'1px',borderColor:'grey'}}>
          <text className="BodyLight" style={{fontSize:'14px',color:'grey',padding:'5px',width:'215px',color:"black",textAlign:'left',marginLeft:'8px'}}>{l.UserSendingTransaction.email}</text>

      </div>
      <div style={{height:'35px',width:'140px',display:'flex',flexDirection:'row',alignItems:'flex-start',justifyContent:'flex-start',borderStyle:'solid',borderWidth:'0px',borderRightWidth:'1px',borderColor:'grey'}}>
          <text className="BodyLight" style={{fontSize:'14px',color:'grey',padding:'5px',width:'140px',color:"black",textAlign:'left',marginLeft:'8px'}}>{l.AppForPurchaseObject}</text>

      </div>
      <div style={{height:'35px',width:'40px',display:'flex',flexDirection:'row',alignItems:'flex-start',justifyContent:'flex-start',borderStyle:'solid',borderWidth:'0px',borderRightWidth:'0px',borderColor:'grey'}}>
          <text className="BodyLight" style={{fontSize:'14px',color:'black',padding:'5px',width:'40px',textAlign:'left',marginLeft:'8px'}}>${(l.TotalAmountToCharge/100).toFixed(2)}</text>

      </div>


    </div>



    </div>

  ))}

    </div>

    </div>


   );
  }
 }

export default DashboardInvidualUser;
