import React, { Component } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import {NavLink,Switch,HashRouter} from "react-router-dom";

import BuyButtonDesktop from './buyButtonWindow.js';
import BuyButtonPreLogin from './buyButtonWindowPreLogin.js';
import BuyButtonAddPaymentSource from './buyButtonWindowAddPaymentSource.js';
import BuyButtonFullFlow from './buyButtonWindowFullFlow.js';

const titlesArray=
[
  '- What is Nifty Gateway?',
  '- What does Nifty Gateway offer?',
  '- How does this Buy with USD Button work?',
  '- How long does this button take to implement?',
  '- How much does it cost for me to implement?',
  '- If there is a fraudulent purchase/chargeback on the USD purchase, am I charged?',
  '- How do I sign up?'
]

const bodyArray =
[
  '	Nifty Gateway is a service that lets you allow your users to buy your crypto collectibles with USD in <10 seconds. This is a sample of the buy button that you could place on your website.',
  'We offer this Buy with USD button, which makes it incredibly easy for your customers to buy your Crypto Tokens with USD. If you click the “Buy Now” button on the above demo it will give you a sample of the user experience.',
  'A user clicks on it and they’re redirected to a niftygateway.com URL. Based on information you put in the URL, you direct what NFT they’re buying. A user completes the buying form (which takes about 30 seconds for a new user, 5 for an existing user), and they’re charged in USD. Nifty Gateway then purchases the NFT from your system using ETH - so your smart contract still receives ETH, exactly as they would if the token was purchased using your current purchasing process. The asset is then sent from one of our wallets to the purchaser’s Metamask or Nifty Gateway account based on what option they chose.',
  'It depends on how your smart contracts are structured, but it should take less than a day. For some cases, we already have your NFTs on our Marketplace, and you can add this buy button to your website with just a few UI modifications. For others we may have to work with you to hard code compatibility with your smart contract purchasing functions, but this should still only take 1-2 days maximum. Reach out so we can get you up and running!',
  'The cost for you to implement the buy button is nothing. We charge the people purchasing your NFTs a fee. ',
  'No. When someone buys something on Nifty Gateway, your smart contract is credited with Ethereum - for you, its the exact same as if they bought it with your system.',
  'Please reach out to partnerships@niftygateway.com to get this button added to your website!',
]

class DemoHomePageCompact extends React.Component {

  state={
    mouseOverAddSourceButton: false,
    isMobile: false,
  }



  render(){
    return(

      <div style={{display:'flex',flexDirection:"column",alignItems:'center',justifyContent:'center',backgroundColor:'white'}}>


      <div style={{width:'600px',height:'300px',backgroundColor:'white',display:'flex',flexDirection:"row",alignItems:'center',justifyContent:'flex-start',}}>

        <img style={{width:'200px',height:'200px',marginLeft:"54px"}} src={'https://s3.amazonaws.com/matterhornphotostorage/superawesomenft.png'} />

          <div style={{marginLeft:'20px',display:'flex',flexDirection:"column",alignItems:'flex-start',justifyContent:'flex-start',}}>
            <text className="bodyLight" style={{fontSize:'30px'}}>Super Awesome NFT</text>
            <text className="bodyNormal" style={{color:'grey',fontSize:'20px',marginTop:'5px'}}>Part of a really Awesome Game, too</text>

              <text className="bodyBold" style={{marginLeft:'55px',marginTop:'15px',fontSize:'30px'}}>$10.00</text>

              <div
                onClick={()=>{window.open('https://niftygateway.com/#/Redirect/BuyButtonRedirectURL/niftyname=Super%20Awesome%20NFT&niftyImageURL=https://s3.amazonaws.com/matterhornphotostorage/superawesomenft.png&niftySellerName=Super%20Awesome%20Co&niftyPriceInCents=1000&niftyContractAddress=xxx&','mywin','width=400,height=800')}}
                className="hoverLight"
                className="LogInButton" onMouseOver={()=>{this.setState({mouseOverAddSourceButton:true})}} onMouseLeave={()=>{this.setState({mouseOverAddSourceButton:false})}}
                style={{background:this.state.mouseOverAddSourceButton?'linear-gradient(-128deg, #00F5C3 0%, #00E0EE 100%)':null,backgroundColor:this.state.mouseOverAddSourceButton?'white':null,width:'220px',height:'40px',borderRadius:'8px',borderStyle:'solid',borderColor:this.state.mouseOverAddSourceButton?'white':"#e4e4e4",borderWidth:'1px',
                display:'flex',marginTop:'15px',marginBottom:'15px',flexDirection:'column',alignItems:'center',justifyContent:'center',cursor:'pointer'}}>
                <text className="bodyNormal" style={{fontSize:'15px',color:this.state.mouseOverAddSourceButton?'white':'black'}}>Buy Now</text>
              </div>

              <text className="bodyNormal" style={{marginLeft:'-4px',color:'grey',fontSize:'13px',marginTop:'1px',width:'230px',textAlign:'center'}}>This will launch the Nifty Gateway Buy with USD Button demo.</text>



          </div>
      </div>


      </div>


    )
  }
}

function NoComponenet() {
  return (
    <div>
      <h2>_</h2>
    </div>
  );
}

export default DemoHomePageCompact
