import React, { Component } from 'react';
import './App.css';
import MaskedInput from 'react-text-mask'

function cc_format(value) {
    var v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '')
    var matches = v.match(/\d{4,16}/g);
    var match = matches && matches[0] || ''
    var parts = []
    var len=match.length

    for (var i=0; i<len; i+=4) {
        parts.push(match.substring(i, i+4))
    }

    if (parts.length) {
        return parts.join(' ')
    } else {
        return value
    }
}



function expDateFormat(value) {

    var substring1= value.substr(0,2);
    if(value.length===2){
      var newString=value+'/';
        return newString
    } else {
      return value
    }



}

class BuyButtonAddPaymentSource extends Component {

    state = {

      mouseOverCompletePaymentButton: false,
      mouseOverChangePaymentMethodButton:false,
      walletSelectionIndex:0,
      mouseOverSendToNiftyGatewayWalletButton: false,
      mouseOverSendToMetaMaskWalletButton: false,
      mouseOverLogInButton: false,
      mouseOverCreateAccountButton: false,
      mouseOverAddSourceButton: false,
      CCNum:'',
      zipCode: null,
      CCCVC: null,
      approveClickAddButtonOnAddPamyentSourcePage: false,

    }

    handleValidateCCInputs(){

    if(this.state.CCNum!==null&this.state.CCExpDate!==null&&this.state.CCCVC!==null&&this.state.zipCode!==null){
      this.setState({approveClickAddButtonOnAddPamyentSourcePage:true});
    }
  }


  createCardTokenAndAddItToThisUsersAccountSelecting = async (cardNumber,expirationMonth,expirationYear,cvc,zipCode) => {
      this.setState({isCreditCardBeingAdded:true});
      var fetchURL = 'https://api.stripe.com/v1/tokens?card[number]=' + cardNumber + '&card[exp_month]=' + expirationMonth + '&card[exp_year]=20' + expirationYear + '&card[cvc]=' + cvc + '&currency=usd&card[address_zip]=' + zipCode;
      fetch(fetchURL, {
        method: 'POST',
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Authorization": "Bearer pk_live_cVGVZJgrtMqPELUnLDx16giP"
        }
      })
      .then(res => res.json())
      .then(data => {

              if(data.error){

              alert(data.error.message);
              this.setState({isCreditCardBeingAdded:false})

            } else {

                console.log(data,'logging the data');

            //    //console.log(data.id);
            //
            //     const apiInstance = apiClient();
            //     const url = URL + 'stripe/add-card/'
            // //    //console.log(data.id,'token');
            //     const sendingData = {
            //       card_token: data.id,
            //     }
            //     apiInstance.post(url, sendingData).then( res => {
            //       ////console.log(res)
            //       ////console.log(res.data)
            //     //  this.props.cardAddedSuccessfullyFunction();
            //       //console.log('card should have been added');
            //       var currentPaymentsArrayLength=this.state.paymentSourcesArray.length;
            //       //console.log(currentPaymentsArrayLength,'currentPaymentsArrayLength');
            //       this.fetchStripeCardsAndShiftToNewCardAfterAddingNewCard(currentPaymentsArrayLength);
            //
            //     }).catch(error => {
            //       //console.log(error)
            //     //  //console.log(error.status)
            //     //  //console.log(error.response)
            //       ////console.log('error on recent tx')
            //       alert("Error adding credit card. Please try again.");
            //         this.setState({isCreditCardBeingAdded:false})
            //       throw error;
            //     });


            }


      })

    }

    render() {
      return (



        <div style={{background: 'linear-gradient(128deg, #00F5C3 0%, #00E0EE 100%)',zIndex:0,display:'flex',flexDirection:'column',alignItems:'center',justifyContent:"flex-start",height:"100vh",minHeight:'700px',width:'100%',overflow:"scroll"}}>

        <div style={{backgroundColor:'white',width:'340px',height:'670px',borderRadius:'5px',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'flex-start',marginTop:'20px'}}>

            <div style={{opacity:'.4',width:"86%",borderStyle:'solid',borderWidth:'0px',borderColor:'#d6d6d6',borderBottomWidth:'1px',height:'112px',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'flex-end'}}>


                  <img src={'https://s3.amazonaws.com/matterhornphotostorage/FaviconNFTG.png'} style={{width:'45px',height:'45px',marginBottom:'5px',marginTop:'10px'}} />
                  <text className="bodyNormal" style={{fontSize:'22px',marginBottom:'10px'}}>Buy with Nifty Gateway</text>


            </div>




            <div style={{width:"86%",borderStyle:'solid',borderWidth:'0px',borderColor:'#d6d6d6',borderLeftWidth:'0px',borderRightWidth:'0px',borderBottomWidth:'1px',height:'512px',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>


                    <text className="bodyNormal" style={{fontSize:'12px',color:'#aeaeae',textAlign:'center',paddingLeft:'20px',paddingRight:'20px',marginBottom:'15px'}}>Welcome, usagriffin@gmail.com</text>


                    <text className="bodyNormal" style={{fontSize:'26px',color:'black',textAlign:'center',paddingLeft:'20px',paddingRight:'20px'}}>Add Payment Method</text>

              <div style={{borderStyle:'solid',borderWidth:'0px',borderBottomWidth:'1px',borderColor:'#dedede',height:'.25px',width:'150px',marginTop:'20px',marginBottom:'15px'}} />

                    <text className="bodyNormal" style={{fontSize:'17px',color:'#444',textAlign:'center',paddingLeft:'20px',paddingRight:'20px',marginBottom:'7.5px'}}>Credit Card</text>


                    <text className="bodyNormal" style={{fontSize:'13px',color:'grey',marginTop:'0px',marginBottom:'2px',alignSelf:'flex-start',marginLeft:'40px'}}>Credit Card Number</text>
                    <input
                    onChange={(event)=>{this.setState({CCNum:cc_format(event.target.value)})}} value={this.state.CCNum}
                    className="bodyNormal" name="cardnumber" autocomplete="cc-number" id="frmCCNum" style={{paddingLeft:'5px',height:'30px',width:'220px',borderStyle:'solid',borderWidth:'1px',borderColor:'#dedede',borderRadius:'4px',fontSize:'14px'}}
                    />

                    <text className="bodyNormal" style={{fontSize:'13px',color:'grey',marginTop:'0px',marginBottom:'2px',alignSelf:'flex-start',marginLeft:'40px'}}>Credit Card Number</text>
                        <input
                        onChange={(event)=>{this.setState({CCNum:cc_format(event.target.value)});this.handleValidateCCInputs();this.detectedCreditCardBrandFunctionInComponent(event.target.value)}} value={this.state.CCNum}
                        className="bodyNormal" name="cardnumber" autocomplete="cc-number" id="frmCCNum" style={{paddingLeft:'5px',height:'30px',width:'220px',borderStyle:'solid',borderWidth:'1px',borderColor:'#dedede',borderRadius:'4px',fontSize:'14px'}}
                        />


                        <text className="bodyNormal" style={{fontSize:'13px',color:'grey',marginTop:'5px',marginBottom:'2px',alignSelf:'flex-start',marginLeft:'40px'}}>Expiration Date</text>
                        <input
                        value={this.state.CCExpDate} placeholder="10/22 or 10/2022" onChange={(event)=>{this.setState({CCExpDate:expDateFormat(event.target.value)});this.handleValidateCCInputs()}}
                        className="bodyNormal"  name="cc-exp"  autocomplete="cc-exp"   style={{paddingLeft:'5px',height:'30px',width:'220px',borderStyle:'solid',borderWidth:'1px',borderColor:'#dedede',borderRadius:'4px',fontSize:'14px'}} />

                        <text className="bodyNormal" style={{fontSize:'13px',color:'grey',marginTop:'5px',marginBottom:'2px',alignSelf:'flex-start',marginLeft:'40px'}}>CVC</text>
                        <input
                        value={this.state.CCCVC} onChange={(event)=>{this.setState({CCCVC:event.target.value});this.handleValidateCCInputs()}}
                        className="bodyNormal" name="cvc"  id="frmCCCVC" autocomplete="cc-csc" style={{paddingLeft:'5px',height:'30px',width:'220px',borderStyle:'solid',borderWidth:'1px',borderColor:'#dedede',borderRadius:'4px',fontSize:'14px'}} />


                    <text className="bodyNormal" style={{fontSize:'13px',color:'grey',marginTop:'5px',marginBottom:'2px',alignSelf:'flex-start',marginLeft:'40px'}}>Zip Code</text>
                    <input
                      value={this.state.zipCode} onChange={(event)=>{this.setState({zipCode:event.target.value});this.handleValidateCCInputs()}}
                    className="bodyNormal" name="ship-zip" id="frmZipS" autocomplete="shipping postal-code"style={{paddingLeft:'5px',height:'30px',width:'220px',borderStyle:'solid',borderWidth:'1px',borderColor:'#dedede',borderRadius:'4px',fontSize:'14px'}} />


              <div style={{borderStyle:'solid',borderWidth:'0px',borderBottomWidth:'1px',borderColor:'#dedede',height:'.25px',width:'150px',marginTop:'20px',marginBottom:'15px'}} />


                                    {this.state.approveClickAddButtonOnAddPamyentSourcePage?
                                        <div

                                    //    onClick={()=>{var curPage=this.state.selectingPage;var newPage = curPage +1;this.setState({selectingPage:newPage})}}
                                          onClick={()=>{this.createCardTokenAndAddItToThisUsersAccountSelecting(this.state.CCNum.replace(/ /g, ""),this.state.CCExpDate.substr(0,2),this.state.CCExpDate.slice(-2),this.state.CCCVC,this.state.zipCode)}}
                                        className="LogInButton" onMouseOver={()=>{this.setState({mouseOverAddSourceButton:true})}} onMouseLeave={()=>{this.setState({mouseOverAddSourceButton:false})}}
                                        style={{background:this.state.mouseOverAddSourceButton?'linear-gradient(-128deg, #00F5C3 0%, #00E0EE 100%)':null,backgroundColor:this.state.mouseOverAddSourceButton?'white':null,width:'220px',height:'40px',borderRadius:'8px',borderStyle:'solid',borderColor:this.state.mouseOverAddSourceButton?'white':"#e4e4e4",borderWidth:'1px',
                                        display:'flex',marginTop:'0px',flexDirection:'column',alignItems:'center',justifyContent:'center',cursor:'pointer'}}>

                                          <text className="bodyNormal" style={{fontSize:'15px',color:this.state.mouseOverAddSourceButton?'white':'black'}}>{this.state.isCreditCardBeingAdded?'Adding Card...':'Add'}</text>

                                        </div>
                                      :
                                      <div


                                      className="LogInButton"
                                      style={{background:this.state.mouseOverAddSourceButton?'linear-gradient(-128deg, #00F5C3 0%, #00E0EE 100%)':null,backgroundColor:this.state.mouseOverAddSourceButton?'white':null,width:'220px',height:'40px',borderRadius:'8px',borderStyle:'solid',borderColor:this.state.mouseOverAddSourceButton?'white':"#e4e4e4",borderWidth:'1px',
                                      display:'flex',marginTop:'0px',flexDirection:'column',alignItems:'center',justifyContent:'center',cursor:'default'}}>

                                        <text className="bodyNormal" style={{fontSize:'15px',color:this.state.mouseOverAddSourceButton?'white':'grey'}}>Add</text>

                                      </div>
                                    }


            </div>


            <div style={{opacity:'.4',width:'293px',height:'86px',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>




            </div>






        </div>

        <div style={{height:'5px',marginTop:'5px',width:'340px',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'flex-start',}}>

          <text onClick={()=>{console.log(this.state)}} className='bodyNormal' style={{fontSize:'13px',color:"white"}}>Questions? Reach out to support@niftygateway.com</text>


        </div>


        </div>


      );
    }
  }

export default BuyButtonAddPaymentSource;
