
const weeksList = [

  {Start:'2019-10-01 00:08:00',End:'2019-11-01 00:08:00'},
  {Start:'2019-09-01 00:08:00',End:'2019-10-01 00:08:00'},
  {Start:'2019-07-01 00:08:00',End:'2019-08-01 00:08:00'},
  {Start:'2019-06-01 00:08:00',End:'2019-07-01 00:08:00'},
  {Start:'2019-05-01 00:08:00',End:'2019-06-01 00:08:00'},
  {Start:'2019-04-01 00:08:00',End:'2019-05-01 00:08:00'},
  {Start:'2019-03-01 00:08:00',End:'2019-04-01 00:08:00'},


  {Start:'2019-02-01 00:08:00',End:'2019-03-01 00:08:00'},
  {Start:'2019-01-01 00:08:00',End:'2019-02-01 00:08:00'},
  {Start:'2018-12-01 00:08:00',End:'2019-01-01 00:08:00'},
]


export function returnMonthsList(){
  const earlyWeeksList = weeksList;

  var currentDate = new Date();
  //console.log(currentDate,'currentDate');
  var newWeeksList = [];
   for(var k=0;k<weeksList.length;k++){
  //  console.log(weeksList[k].End,'weeksList[k]')
    var weeksListDate = new Date(weeksList[k].Start);
  //  console.log(weeksListDate,'weeksListDate');
    if(weeksListDate.getTime()<currentDate.getTime()){
  //    console.log(weeksList[k].Start + ' to ' + weeksList[k].End,'this is the date range that should apply');
      newWeeksList.push(weeksList[k]);
    }
  }
  return newWeeksList
}


  {/*}


 {Start:'2019-07-03 00:08:00',End:'2019-07-9 00:08:00'},
 {Start:'2019-06-26 00:08:00',End:'2019-07-02 00:08:00'},
 {Start:'2019-06-19 00:08:00',End:'2019-06-25 00:08:00'},
 {Start:'2019-06-12 00:08:00',End:'2019-06-18 00:08:00'},
 {Start:'2019-06-05 00:08:00',End:'2019-06-11 00:08:00'},
 {Start:'2019-05-29 00:08:00',End:'2019-06-04 00:08:00'},
 {Start:'2019-05-22 00:08:00',End:'2019-05-28 00:08:00'},
 {Start:'2019-05-15 00:08:00',End:'2019-05-21 00:08:00'},
 {Start:'2019-05-08 00:08:00',End:'2019-05-14 00:08:00'},
 {Start:'2019-05-01 00:08:00',End:'2019-05-07 00:08:00'},
 {Start:'2019-04-24 00:08:00',End:'2019-04-30 00:08:00'},
 {Start:'2019-04-17 00:08:00',End:'2019-04-23 00:08:00'},
 {Start:'2019-04-10 00:08:00',End:'2019-04-16 00:08:00'},
 {Start:'2019-04-03 00:08:00',End:'2019-04-09 00:08:00'},

  {Start:'2019-03-27 00:08:00',End:'2019-04-02 00:08:00'},
  {Start:'2019-03-20 00:08:00',End:'2019-03-26 00:08:00'},
  {Start:'2019-03-13 00:08:00',End:'2019-03-19 00:08:00'},
  {Start:'2019-03-06 00:08:00',End:'2019-03-12 00:08:00'},
  {Start:'2019-02-27 00:08:00',End:'2019-03-05 00:08:00'},
 */}


export default weeksList;
