import React, { Component } from 'react';
import './App.css';
import axios from 'axios';
import {NavLink} from 'react-router-dom';
import Sidebar from './Sidebar.js';
import NumberFormat from 'react-number-format';

import {returnWeeksList,returnWeeksListLimitedDates} from './weeksList.js';

  // const weeksList = returnWeeksList(604800000);



var newWeeksList = returnWeeksListLimitedDates(86400000,(new Date(window.location.href.substr(-10).toString())).getTime());
var weeksList = [newWeeksList[newWeeksList.length-1]];
// const weeksList = [newWeeksList[newWeeksList.length-1]];

class DashboardIndividualDay extends Component {

  constructor(){
    super();
    var week = window.location.href.substr(-1);
    this.state={
      weeksList: weeksList,
      thisWeeksData: {Start:'',End:'',data:{data:[]}},
      week: week,
    }
  }


    //console.log(resultArray);




  async componentDidMount(){

    // console.log(newWeeksList,'newWeeksList','window.location.href.substr(10)',window.location.href.substr(-10));
    // console.log(weeksList,'weeksList',newWeeksList,window.location.href.substr(-10),newWeeksList2,'newWeeksList2');


     newWeeksList = returnWeeksListLimitedDates(86400000,(new Date(window.location.href.substr(-10).toString())).getTime());
     weeksList = [newWeeksList[newWeeksList.length-1]];

    var weeklyTransactionsArray = [];
    var week = window.location.href.substr(-1);
    var i = 0;
    console.log(i,'logging i');

     var thisWeeksData = {Start:weeksList[i].Start,End:weeksList[i].End};
  //   console.log(weeksList[i].Start,'weeksList[i].Start',weeksList[i].End,'weeksList[i].End');
     var url = 'https://api.niftygateway.com/dashboard/date-range-co/?start_date='+weeksList[i].Start+'&end_date='+weeksList[i].End;
    // console.log(url,'logging url');
     await axios.get(url).then(res => {
    //   console.log(res,'logging res from date range number'+i);
       thisWeeksData.data = res.data;
       // var newArray = res.data.data.reverse();
       // thisWeeksData.data.data = newArray;
       this.setState({thisWeeksData:thisWeeksData});
    //    weeklyTransactionsArray.push(thisWeeksData);
       console.log(thisWeeksData,'thisWeeksData')

      // this.setState({pa  ymentSourcesArray: res.data,selectingPage:3,selectedPaymentIndex:newCardIndex,paymentSourcesLoading:false,isCreditCardBeingAdded:false});
     }).catch(error => {
       console.log(error.response,'error response')
       throw error;
     });


    // console.log(weeklyTransactionsArray,'weeklyTransactionsArray');

  // console.log(weeklyTransactionsArray,'weeklyTransactionsArray');
  // this.setState({weeklyTransactionsArray: weeklyTransactionsArray});
  // console.log(weeklyTransactionsArray,'weeklyTransactionsArray');

  var daysArray = [];
  for(var j =0;j<7;j++){

  }

  var numOfAxie = 0;
  var numOfGU = 0;
  var numOfOther = 0;

  var sumTotal = 0;

  for (var i=0;i<thisWeeksData.data.data.length;i++){
    if(thisWeeksData.data.data[i].AppForPurchaseObject==='AxieInfinityLand'){
      numOfAxie++;
    } else if (thisWeeksData.data.data[i].AppForPurchaseObject==='GodsUnchained'){
      numOfGU++;
    } else {
      numOfOther++;
    }

    sumTotal = sumTotal + thisWeeksData.data.data[i].TotalAmountToCharge;
  }

  var percentages = {Axie:numOfAxie/(thisWeeksData.data.data.length),GodsUnchained:(numOfGU/thisWeeksData.data.data.length),Other:(numOfOther/thisWeeksData.data.data.length)};

  this.setState({totalWeeklyRev:sumTotal,percentageAxie:percentages.Axie,percentageGU:percentages.GodsUnchained,percentageOther:percentages.Other});

  }



  fetchDataForATimePeriod(startDate,endDate){
    axios.get()
  }


  render() {
   return (

    <div >

    <div style={{width:'100%',position:'absolute',top:0,height:'120px',display:'flex',flexDirection:"column",alignItems:'center',justifyContent:"center"}}>
    <text className="bodyMedium" style={{fontSize:'55px'}}>{new Date(this.state.thisWeeksData.Start).getMonth()+1}/{new Date(this.state.thisWeeksData.Start).getDate()} to {new Date(this.state.thisWeeksData.End).getMonth()+1}/{new Date(this.state.thisWeeksData.End).getDate()-1}</text>

     </div>
     <Sidebar />

     <div style={{position:"absolute",top:150,left:650,display:'flex',flexDirection:'column',alignItems:'flex-start',justifyContent:'flex-start'}}>
         <text className="BodyNormal" style={{fontSize:'13px'}}>Axie Infinity: {Number(100*this.state.percentageAxie).toFixed(2)} </text>
         <text className="BodyNormal" style={{fontSize:'13px',marginTop:'15px'}}>Gods Unchained: {Number(100*this.state.percentageGU).toFixed(2)} </text>
         <text className="BodyNormal" style={{fontSize:'13px',marginTop:'15px'}}>Other: {Number(100*this.state.percentageOther).toFixed(2)} </text>
     </div>

      <text className="BodyNormal" style={{fontSize:'18px',position:'absolute',top:200,left:1050}}>Total: {<NumberFormat value={(this.state.totalWeeklyRev/100).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />}</text>

     <text className="BodyNormal" style={{fontSize:'30px',position:'absolute',top:180,left:350}}>{new Date(this.state.thisWeeksData.Start).getMonth()+1}/{new Date(this.state.thisWeeksData.Start).getDate()} to {new Date(this.state.thisWeeksData.End).getMonth()+1}/{new Date(this.state.thisWeeksData.End).getDate()-1}</text>

    <div style={{position:'absolute',top:240,left:290,width:'900px',borderStyle:"solid",borderWidth:'0px',borderTopWidth:"1px",borderColor:'lightgrey',height:'1px'}}> </div>

    <div style={{position:'absolute',top:270,left:250,width:'1000px',display:"flex",flexDirection:'column',alignItems:'center',justifyContent:'flex-start',}}>

    {this.state.thisWeeksData.data.data.map((l,i) => (
    <div style={{width:'850px',display:"flex",flexDirection:"row",alignItems:'flex-start',justifyContent:'flex-start'}}>
    <div style={{width:'900px',borderStyle:'solid',borderWidth:'1px',borderBottomWidth:i+1===this.state.thisWeeksData.data.data.length?'1px':'0px',borderColor:'grey',height:'35px',display:'flex',flexDirection:'row',alignItems:'flex-start',justifyContent:'flex-start'}}>
      <div style={{height:'35px',width:'50px',display:'flex',flexDirection:'row',alignItems:'flex-start',justifyContent:'flex-start',borderStyle:'solid',borderWidth:'0px',borderRightWidth:'1px',borderColor:'grey'}}>
        <text className="BodyLight" style={{fontSize:'17px',color:'grey',padding:'5px',width:'50px',textAlign:'left',marginLeft:'8px'}}>{i+1}</text>

      </div>
      <div style={{height:'35px',width:'215px',display:'flex',flexDirection:'row',alignItems:'flex-start',justifyContent:'flex-start',borderStyle:'solid',borderWidth:'0px',borderRightWidth:'1px',borderColor:'grey'}}>
          <text className="BodyLight" style={{fontSize:'14px',color:'grey',padding:'5px',width:'215px',color:"black",textAlign:'left',marginLeft:'8px'}}>{new Date(l.Timestamp).toLocaleDateString() + " " + new Date(l.Timestamp).toLocaleTimeString()}</text>

      </div>
      <NavLink to={"/Dashboard/user/"+l.UserSendingTransaction.email}>
      <div style={{height:'35px',width:'255px',display:'flex',flexDirection:'row',alignItems:'flex-start',justifyContent:'flex-start',borderStyle:'solid',borderWidth:'0px',borderRightWidth:'1px',borderColor:'grey'}}>
          <text className="BodyLight" style={{textDecoration:"underline",color:'blue',fontSize:'14px',color:'blue',padding:'5px',width:'215px',color:"blue",textAlign:'left',marginLeft:'8px'}}>{l.UserSendingTransaction.email}</text>

      </div>
      </NavLink>
      <div style={{height:'35px',width:'140px',display:'flex',flexDirection:'row',alignItems:'flex-start',justifyContent:'flex-start',borderStyle:'solid',borderWidth:'0px',borderRightWidth:'1px',borderColor:'grey'}}>
          <text className="BodyLight" style={{fontSize:'14px',color:'grey',padding:'5px',width:'140px',color:"black",textAlign:'left',marginLeft:'8px'}}>{l.AppForPurchaseObject}</text>

      </div>
      <div style={{height:'35px',width:'100px',display:'flex',flexDirection:'row',alignItems:'flex-start',justifyContent:'flex-start',borderStyle:'solid',borderWidth:'0px',borderRightWidth:'1px',borderColor:'grey'}}>
          <text className="BodyLight" style={{fontSize:'14px',color:'grey',padding:'5px',width:'100px',color:"black",textAlign:'left',marginLeft:'8px'}}>{l.PaymentProcessor}</text>

      </div>
      <div style={{height:'35px',width:'40px',display:'flex',flexDirection:'row',alignItems:'flex-start',justifyContent:'flex-start',borderStyle:'solid',borderWidth:'0px',borderRightWidth:'0px',borderColor:'grey'}}>
          <text className="BodyLight" style={{fontSize:'14px',color:'black',padding:'5px',width:'40px',textAlign:'left',marginLeft:'8px'}}>${(l.TotalAmountToCharge/100).toFixed(2)}</text>

      </div>


    </div>



    </div>

  ))}

    </div>

    </div>


   );
  }
 }

export default DashboardIndividualDay;
