import React, { Component } from 'react';
import './App.css';
import axios from 'axios';
import {NavLink} from 'react-router-dom';

import {returnWeeksList} from './weeksList.js';

const weeksList = returnWeeksList(604800000);


class Sidebar extends Component {

  constructor(){
    super();
    this.state={
      weeksList: weeksList,

    }
  }



  render() {
   return (

    <div style={{position:'fixed',top:140,left:75,width:'175px',borderStyle:'solid',borderColor:"lightgrey",borderWidth:'0px',borderRightWidth:'1px',height:'600px',display:'flex',flexDirection:'column',alignItems:"flex-start",justifyContent:"flex-start"}}>
     <text className="bodyBold" style={{marginLeft:"0px",textAlign:'left',fontSize:'18px',marginBottom:'0px',color:"black",textDecoration:"underline"}}>Dashboards:</text>
    <NavLink to={"/Dashboard"} style={{marginTop:'15px',textDecoration:'none'}}>
     <text  className="bodyNormalHoverLight" style={{textAlign:'left',fontSize:'15px',marginTop:'30px',color:"blue",textDecoration:'underline'}}>WoW Transactions </text>
     </NavLink>

    <NavLink to={"/Dashboard/MonthOverMonth"} style={{marginTop:'15px',textDecoration:'none'}}>
     <text  className="bodyNormalHoverLight" style={{textAlign:'left',fontSize:'15px',marginTop:'30px',color:"blue",textDecoration:'underline'}}>MoM Transactions </text>
     </NavLink>

     <NavLink to={"/Dashboarddod"} style={{marginTop:'15px',textDecoration:'none'}}>
      <text  className="bodyNormalHoverLight" style={{textAlign:'left',fontSize:'15px',marginTop:'30px',color:"blue",textDecoration:'underline'}}>Past 15 Days</text>
      </NavLink>

      <NavLink to={"/Dashboarddodpast90"} style={{marginTop:'15px',textDecoration:'none'}}>
       <text className="bodyNormalHoverLight" style={{textAlign:'left',fontSize:'15px',marginTop:'30px',color:"blue",textDecoration:'underline'}}>Past 90 Days</text>
       </NavLink>


     <NavLink to={"/Dashboard/PastYear"} style={{marginTop:'15px',textDecoration:'none'}}>
      <text  className="bodyNormalHoverLight" style={{textAlign:'left',fontSize:'15px',marginTop:'30px',color:"blue",textDecoration:'underline'}}>All Time</text>
      </NavLink>



     {weeksList.map((l,i) => (
       i<4?
       <NavLink to={"/Dashboard/week/"+i} style={{marginTop:'15px',textDecoration:'none'}}>
        <text className="bodyNormalHoverLight"  style={{width:'400px',textAlign:'left',fontSize:'15px',color:"blue",textDecoration:'underline'}}>{new Date(l.Start).getMonth()+1}/{new Date(l.Start).getDate()} to {new Date(l.End).getMonth()+1}/{new Date(l.End).getDate()-1}</text>
       </NavLink>
       :null
     ))}

     <NavLink to={"/DashboardSortByUsers"} style={{marginTop:'15px',textDecoration:'none'}}>
      <text  className="bodyNormalHoverLight" style={{textAlign:'left',fontSize:'15px',marginTop:'30px',color:"blue",textDecoration:'underline'}}>Users</text>
      </NavLink>

      <NavLink to={"/DashboardSortByGames"} style={{marginTop:'15px',textDecoration:'none'}}>
       <text  className="bodyNormalHoverLight" style={{textAlign:'left',fontSize:'15px',marginTop:'30px',color:"blue",textDecoration:'underline'}}>Games</text>
       </NavLink>


     <img style={{position:"fixed",top:70,left:75,width:'50px'}} src={'https://s3.amazonaws.com/matterhornphotostorage/FaviconNFTG.png'} />

     </div>



   );
  }
 }

export default Sidebar;
