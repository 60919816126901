import React, { Component } from "react";

import {
  Route,
  NavLink,
  HashRouter
} from "react-router-dom";



import './transactionHistory.css';



import Checkbox from '@material-ui/core/Checkbox';

class BuyButtonSandboxLiveHomePage extends Component {

  constructor(){
    super();
  this.state={
    originURLInput: 'https://opensea.io',
    contractAddressInput: null,
    tokenIDInput: null,
    isNiftyGatewayWalletDisabled: false,
  }
}

  render(){
    return(
      <div style={{display:"flex",flexDirection:'column',alignItems:'center',justifyContent:'center',height:'650px'}}>

          <text className="bodyNormal" style={{fontSize:'45px',marginBottom:'30px'}}>Buy Button Sandbox</text>

          <text className="bodyNormal" stlye={{fontSize:'18px',marginBottom:'3px'}}>Origin URL</text>
          <input value={this.state.originURLInput} onChange={(event)=>{this.setState({originURLInput:event.target.value})}} style={{borderRadius:'8px',width:'600px',fontSize:'15px',color:'black',paddingLeft:'5px',height:'42px',marginBottom:'20px',borderStyle:'solid',borderWidth:'1px',borderColor:'lightgrey'}} />

          <text className="bodyNormal" stlye={{fontSize:'18px',marginBottom:'3px'}}>Contract Address</text>
          <input value={this.state.contractAddressInput} onChange={(event)=>{this.setState({contractAddressInput:event.target.value})}} style={{borderRadius:'8px',width:'600px',fontSize:'15px',color:'black',paddingLeft:'5px',height:'42px',marginBottom:'20px',borderStyle:'solid',borderWidth:'1px',borderColor:'lightgrey'}} />

          <text className="bodyNormal" stlye={{fontSize:'18px',marginBottom:'3px'}}>Token ID</text>
          <input value={this.state.tokenIDInput} onChange={(event)=>{this.setState({tokenIDInput:event.target.value})}} style={{borderRadius:'8px',width:'600px',fontSize:'15px',color:'black',paddingLeft:'5px',height:'42px',marginBottom:'20px',borderStyle:'solid',borderWidth:'1px',borderColor:'lightgrey'}} />

          <text className="bodyNormal" stlye={{fontSize:'18px',marginBottom:'3px'}}>Disable Nifty Gateway Wallet for this purchase:</text>
          <Checkbox checked={this.state.isNiftyGatewayWalletDisabled} onChange={(event)=>{this.setState({isNiftyGatewayWalletDisabled:!this.state.isNiftyGatewayWalletDisabled})}} style={{marginBottom:'40px'}} />

      <div onClick={()=>{window.open('https://niftygateway.com/#/LiveBuyButtonRedirectURL/contractAddress='+this.state.contractAddressInput+'&tokenID='+this.state.tokenIDInput+'&isNiftyGatewayWalletDisabled='+this.state.isNiftyGatewayWalletDisabled+'&','blank','width=400,height=800')}}
      style={{width:'200px',height:'65px',borderStyle:'solid',borderRadius:'6px',borderWidth:'1px',borderColor:'black',cursor:'pointer',display:'flex',flexDirection:"column",alignItems:"center",justifyContent:"center"}}>
      <text className='bodyBold' style={{fontSize:'20px',color:'black'}}>Test Link</text>

      </div>


      <text className='bodyNormal' style={{marginTop:'20px',fontSize:'15px',width:'900px'}}>This will link to: <text style={{color:'blue'}}>{"https://niftygateway.com/#/LiveBuyButtonRedirectURL/"}{this.state.contractAddressInput===null||this.state.contractAddressInput===''?null:'contractAddress='+this.state.contractAddressInput+'&'}{this.state.tokenIDInput===null||this.state.tokenIDInput===''?null:'tokenID='+this.state.tokenIDInput+'&'}{this.state.isNiftyGatewayWalletDisabled===true?'isNiftyGatewayWalletDisabled=true&':null}</text></text>

      <text className='bodyNormal' style={{marginTop:'20px',fontSize:'15px',width:'900px'}}>As if it was coming from: {this.state.originURLInput}</text>


      </div>

    )
  }
}

export default BuyButtonSandboxLiveHomePage;
