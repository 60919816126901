
const weeksList = [

  {Start:'2019-07-24 08:00:00',End:'2019-07-31 08:00:00'},
  {Start:'2019-07-17 08:00:00',End:'2019-07-24 08:00:00'},
  {Start:'2019-07-10 08:00:00',End:'2019-07-17 08:00:00'},
  {Start:'2019-07-03 08:00:00',End:'2019-07-10 08:00:00'},
  {Start:'2019-06-26 08:00:00',End:'2019-07-03 08:00:00'},

  {Start:'2019-06-19 08:00:00',End:'2019-06-26 08:00:00'},
  {Start:'2019-06-12 08:00:00',End:'2019-06-19 08:00:00'},
  {Start:'2019-06-05 08:00:00',End:'2019-06-12 08:00:00'},
  {Start:'2019-05-29 08:00:00',End:'2019-06-05 08:00:00'},

  {Start:'2019-05-22 08:00:00',End:'2019-05-29 08:00:00'},
  {Start:'2019-05-15 08:00:00',End:'2019-05-22 08:00:00'},
  {Start:'2019-05-08 08:00:00',End:'2019-05-15 08:00:00'},
  {Start:'2019-05-01 08:00:00',End:'2019-05-08 08:00:00'},

  {Start:'2019-04-24 08:00:00',End:'2019-05-01 08:00:00'},
  {Start:'2019-04-17 08:00:00',End:'2019-04-24 08:00:00'},
  {Start:'2019-04-10 08:00:00',End:'2019-04-17 08:00:00'},
  {Start:'2019-04-03 08:00:00',End:'2019-04-10 08:00:00'},

  {Start:'2019-03-27 08:00:00',End:'2019-04-03 08:00:00'},
  {Start:'2019-03-20 08:00:00',End:'2019-03-27 08:00:00'},
  {Start:'2019-03-13 08:00:00',End:'2019-03-20 08:00:00'},
  {Start:'2019-03-06 08:00:00',End:'2019-03-13 08:00:00'},
  {Start:'2019-02-27 08:00:00',End:'2019-03-06 08:00:00'},

  {Start:'2019-02-20 08:00:00',End:'2019-02-27 08:00:00'},
  {Start:'2019-02-13 08:00:00',End:'2019-02-20 08:00:00'},
  {Start:'2019-02-06 08:00:00',End:'2019-02-13 08:00:00'},
  {Start:'2019-01-30 08:00:00',End:'2019-02-06 08:00:00'},
  {Start:'2019-01-23 08:00:00',End:'2019-01-30 08:00:00'},
  {Start:'2019-01-16 08:00:00',End:'2019-01-23 08:00:00'},
  {Start:'2019-01-09 08:00:00',End:'2019-01-16 08:00:00'},
  {Start:'2019-01-02 08:00:00',End:'2019-01-09 08:00:00'},
  {Start:'2018-12-26 08:00:00',End:'2019-01-02 08:00:00'},
  {Start:'2018-12-19 08:00:00',End:'2018-12-26 08:00:00'},
  {Start:'2018-12-12 08:00:00',End:'2018-12-19 08:00:00'},
  {Start:'2018-12-05 08:00:00',End:'2018-12-12 08:00:00'},
]

export function returnWeeksList(intervalInMilliseconds){
  const earlyWeeksList = weeksList;

  //6.048e+8
  // 604800000 is the number of milliseconds in a week


  var currentDate = new Date();
  var currentDateInMilliseoncds = currentDate.getTime();

  var startDate = new Date('12/05/2018 00:00:00');
  var startDateInMilliseconds = startDate.getTime();


  var numberOfWeeks = Math.ceil((currentDateInMilliseoncds-startDateInMilliseconds)/intervalInMilliseconds);

  var newWeeksList = [];
  var currentWeekInMilliseconds = startDate.getTime();
     for(var k=0;k<numberOfWeeks;k++){

       var nextWeek = currentWeekInMilliseconds+intervalInMilliseconds;

       var currentYearStartDate = new Date(currentWeekInMilliseconds).getUTCFullYear();
       var currentMonthStartDate = new Date(currentWeekInMilliseconds).getUTCMonth()+1;
      var currentDayStartDate = new Date(currentWeekInMilliseconds).getUTCDate();

       var nextWeekStartDateYear = new Date(nextWeek).getUTCFullYear();
       var nextWeekStartDateMonth = new Date(nextWeek).getUTCMonth()+1;
       var nextWeekStartDateDay = new Date(nextWeek).getUTCDate();

      var thisWeekDateStartEndCorrectFormatStart = currentYearStartDate+'-'+currentMonthStartDate+'-'+currentDayStartDate+' 08:00:00';
      var thisWeekDateStartEndCorrectFormatEnd = nextWeekStartDateYear+'-'+nextWeekStartDateMonth+'-'+nextWeekStartDateDay+' 08:00:00';

      var weekListObjectToAdd = {Start:thisWeekDateStartEndCorrectFormatStart,End:thisWeekDateStartEndCorrectFormatEnd};

      newWeeksList.push(weekListObjectToAdd);

      currentWeekInMilliseconds = currentWeekInMilliseconds + intervalInMilliseconds;

    }

  return newWeeksList.reverse()
}


export function returnWeeksListLimitedDatesOneDayAhead(intervalInMilliseconds,startTimeInMilliseconds){
  const earlyWeeksList = weeksList;

  //6.048e+8
  // 604800000 is the number of milliseconds in a week


  var currentDate = new Date();
  var currentDateInMilliseoncds = currentDate.getTime()+86400000;


  var startDateInMilliseconds = startTimeInMilliseconds


  var numberOfWeeks = Math.ceil((currentDateInMilliseoncds-startDateInMilliseconds)/intervalInMilliseconds);

  var newWeeksList = [];
  var currentWeekInMilliseconds = startDateInMilliseconds;
     for(var k=0;k<numberOfWeeks;k++){

       var nextWeek = currentWeekInMilliseconds+intervalInMilliseconds;

       var currentYearStartDate = new Date(currentWeekInMilliseconds).getUTCFullYear();
       var currentMonthStartDate = new Date(currentWeekInMilliseconds).getUTCMonth()+1;
      var currentDayStartDate = new Date(currentWeekInMilliseconds).getUTCDate();

       var nextWeekStartDateYear = new Date(nextWeek).getUTCFullYear();
       var nextWeekStartDateMonth = new Date(nextWeek).getUTCMonth()+1;
       var nextWeekStartDateDay = new Date(nextWeek).getUTCDate();

      var thisWeekDateStartEndCorrectFormatStart = currentYearStartDate+'-'+currentMonthStartDate+'-'+currentDayStartDate+' 08:00:00';
      var thisWeekDateStartEndCorrectFormatEnd = nextWeekStartDateYear+'-'+nextWeekStartDateMonth+'-'+nextWeekStartDateDay+' 08:00:00';

      var weekListObjectToAdd = {Start:thisWeekDateStartEndCorrectFormatStart,End:thisWeekDateStartEndCorrectFormatEnd};

      newWeeksList.push(weekListObjectToAdd);

      currentWeekInMilliseconds = currentWeekInMilliseconds + intervalInMilliseconds;

    }



  return newWeeksList.reverse()
}

export function returnWeeksListLimitedDates(intervalInMilliseconds,startTimeInMilliseconds){
  const earlyWeeksList = weeksList;

  //6.048e+8
  // 604800000 is the number of milliseconds in a week


  var currentDate = new Date();
  var currentDateInMilliseoncds = currentDate.getTime();


  var startDateInMilliseconds = startTimeInMilliseconds


  var numberOfWeeks = Math.ceil((currentDateInMilliseoncds-startDateInMilliseconds)/intervalInMilliseconds);

  var newWeeksList = [];
  var currentWeekInMilliseconds = startDateInMilliseconds;
     for(var k=0;k<numberOfWeeks;k++){

       var nextWeek = currentWeekInMilliseconds+intervalInMilliseconds;

       var currentYearStartDate = new Date(currentWeekInMilliseconds).getUTCFullYear();
       var currentMonthStartDate = new Date(currentWeekInMilliseconds).getUTCMonth()+1;
      var currentDayStartDate = new Date(currentWeekInMilliseconds).getUTCDate();

       var nextWeekStartDateYear = new Date(nextWeek).getUTCFullYear();
       var nextWeekStartDateMonth = new Date(nextWeek).getUTCMonth()+1;
       var nextWeekStartDateDay = new Date(nextWeek).getUTCDate();

      var thisWeekDateStartEndCorrectFormatStart = currentYearStartDate+'-'+currentMonthStartDate+'-'+currentDayStartDate+' 08:00:00';
      var thisWeekDateStartEndCorrectFormatEnd = nextWeekStartDateYear+'-'+nextWeekStartDateMonth+'-'+nextWeekStartDateDay+' 08:00:00';

      var weekListObjectToAdd = {Start:thisWeekDateStartEndCorrectFormatStart,End:thisWeekDateStartEndCorrectFormatEnd};

      newWeeksList.push(weekListObjectToAdd);

      currentWeekInMilliseconds = currentWeekInMilliseconds + intervalInMilliseconds;

    }



  return newWeeksList.reverse()
}




export default weeksList;
