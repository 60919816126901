import React, { Component } from 'react';
import './App.css';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import Slide from '@material-ui/core/Slide';
import Fade from '@material-ui/core/Fade';
import Grow from '@material-ui/core/Grow';
import Zoom from '@material-ui/core/Zoom';
import Collapse from '@material-ui/core/Collapse';
import {FaChevronLeft} from 'react-icons/fa';
import {Number, Cvc, Expiration} from 'react-credit-card-primitives';
import Visa from 'payment-icons/svg/flat/visa.svg';
import Mastercard from 'payment-icons/svg/flat/mastercard.svg';
import Amex from 'payment-icons/svg/flat/amex.svg';
import Default from 'payment-icons/svg/flat/default.svg';
import ReactPlaid, { DEV_ENV, PROD_ENV, CONNECT_PRODUCT } from "react-plaid";

const paymentInfoArray=
['4242','0005']


function cc_format(value) {



    var v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '')
    var matches = v.match(/\d{4,16}/g);
    var match = matches && matches[0] || ''
    var parts = []
    var len=match.length

    for (var i=0; i<len; i+=4) {
        parts.push(match.substring(i, i+4))
    }

    if (parts.length) {
        return parts.join(' ')
    } else {
        return value
    }
}

class BuyButtonFullFlow extends Component {

  state = {
    mouseOverCompletePaymentButton: false,
    mouseOverChangePaymentMethodButton:false,
    walletSelectionIndex:0,
    mouseOverSendToNiftyGatewayWalletButton: false,
    mouseOverSendToMetaMaskWalletButton: false,
    selectingPage:1,
    mouseOverPaymentSourcesArray: [false,false,false,false,false,false,false,false,false,false],
    LogInButtonEnabled: false,
    CreateAccountButtonEnabled: false,
    enteredCCNumIsInvalid: false,
    approveClickAddButtonOnAddPamyentSourcePage: false,
    errorMessageOnLoginSlashCreateAccountPage: null,
    approveClickAddButtonOnCFullFeaturedAccountAddPamyentSourcePage: false,
    detectedCreditCardBrand:null,

    emailOnLoginScreen: null,
    passwordOnLoginScreen: null,
    CCnum: null,
    CCExpDate: null,
    CCCVC: null,
    paymentSourcesArray: ['4242','0005','1111','2222','3333','2222','8888'],
    selectedPaymentIndex: 0,


    open:false,





  }

  handleOnSuccess(token, metadata) {
    console.log(token,'token',metadata,'metadata');
  }

  detectedCreditCardBrandFunctionInComponent(value){
    if(value.substring(0,1)==='4'){
      this.setState({detectedCreditCardBrand:'Visa'});
    } else if(value.substring(0,1)==='5'){
      this.setState({detectedCreditCardBrand:'Mastercard'});
    } else if(value.substring(0,2)==='37'){
        this.setState({detectedCreditCardBrand:'American Express'});
    } else {
      this.setState({detectedCreditCardBrand:null});
    }
  }

  componentDidMount(){

  }

  handleChangeEmailForLoginScreen(value){
    this.setState({emailOnLoginScreen:value});
    console.log(value.indexOf('@'),'value.indexof(@)');
      console.log(value.indexOf('.'),'value.indexof(@)');
      console.log(this.state.passwordOnLoginScreen,'value.indexof(@)');
    if(value.indexOf('@')!==-1&&value.indexOf('.')!==-1&&this.state.passwordOnLoginScreen!==null){
      this.setState({CreateAccountButtonEnabled:true,LogInButtonEnabled:true})
    }
  }

  handleChangePasswordForLoginScreen(value){
    this.setState({passwordOnLoginScreen:value});
    if(this.state.emailOnLoginScreen.indexOf('@')!==-1&&this.state.emailOnLoginScreen.indexOf('.')!==-1&&this.state.passwordOnLoginScreen!==null){
      this.setState({CreateAccountButtonEnabled:true,LogInButtonEnabled:true})
    }
  }

  handleValidateCCInputs(){

    if(this.state.CCNum!==null&this.state.CCExpDate!==null&&this.state.CCCVC!==null){
      this.setState({approveClickAddButtonOnAddPamyentSourcePage:true});
    }
  }


  render() {
    return (



      <div style={{background: 'linear-gradient(128deg, #00F5C3 0%, #00E0EE 100%)',zIndex:9999,display:'flex',flexDirection:'column',alignItems:'center',justifyContent:"flex-start",height:"100vh",minHeight:'700px',width:'100%',overflow:"scroll"}}>

      <div style={{backgroundColor:'white',width:'340px',height:'670px',borderRadius:'5px',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'flex-start',marginTop:'20px'}}>

        {this.state.selectingPage===3?
            <Fade in = {this.state.selectingPage===3} timeout={1000} >
          <div style={{opacity:this.state.selectingPage===3||this.state.selectingPage===4?'1':'.4',width:"86%",borderStyle:'solid',borderWidth:'0px',borderColor:'#d6d6d6',borderBottomWidth:'1px',height:'112px',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'flex-end'}}>


                <img src={'https://s3.amazonaws.com/matterhornphotostorage/FaviconNFTG.png'} style={{width:'45px',height:'45px',marginBottom:'5px',marginTop:'10px'}} />
                <text className="bodyNormal" style={{fontSize:'22px',marginBottom:'10px',cursor:'default'}}>Buy with Nifty Gateway</text>


          </div>
            </Fade>
          :
          <div style={{opacity:this.state.selectingPage===3?'1':'.4',width:"86%",borderStyle:'solid',borderWidth:'0px',borderColor:'#d6d6d6',borderBottomWidth:'1px',height:'112px',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'flex-end'}}>


                <img src={'https://s3.amazonaws.com/matterhornphotostorage/FaviconNFTG.png'} style={{width:'45px',height:'45px',marginBottom:'5px',marginTop:'10px'}} />
                <text className="bodyNormal" style={{fontSize:'22px',marginBottom:'10px',cursor:'default'}}>Buy with Nifty Gateway</text>


          </div>
        }

          {this.state.selectingPage===1?

            <div style={{width:"86%",borderStyle:'solid',borderWidth:'0px',borderColor:'#d6d6d6',borderLeftWidth:'0px',borderRightWidth:'0px',borderBottomWidth:'1px',height:'512px',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>

                    <text className="bodyNormal" style={{fontSize:'26px',color:'black',textAlign:'center',paddingLeft:'20px',paddingRight:'20px'}}>Log in or Create Account to continue</text>

              <div style={{borderStyle:'solid',borderWidth:'0px',borderBottomWidth:'1px',borderColor:'#dedede',height:'.25px',width:'150px',marginTop:'20px',marginBottom:'20px'}} />


                    <text className="bodyNormal" style={{fontSize:'13px',color:'grey',marginTop:'0px',marginBottom:'2px',alignSelf:'flex-start',marginLeft:'40px'}}>Email</text>
                    <input className="bodyNormal" value={this.state.emailOnLoginScreen} onChange={(event)=>{this.setState({emailOnLoginScreen:event.target.value})}} type="email" name="email" style={{paddingLeft:'5px',height:'30px',width:'220px',borderStyle:'solid',borderWidth:'1px',borderColor:'#dedede',borderRadius:'4px',fontSize:'14px'}} />

                    <text className="bodyNormal" style={{fontSize:'13px',color:'grey',marginTop:'5px',marginBottom:'2px',alignSelf:'flex-start',marginLeft:'40px'}}>Password</text>
                    <input className="bodyNormal" value={this.state.passwordOnLoginScreen} onChange={(event)=>{this.setState({passwordOnLoginScreen:event.target.value})}} type="password" name="password" style={{paddingLeft:'5px',height:'30px',width:'220px',borderStyle:'solid',borderWidth:'1px',borderColor:'#dedede',borderRadius:'4px',fontSize:'14px',marginBottom:'20px'}} />

                    {this.state.errorMessageOnLoginSlashCreateAccountPage!==null?
                    <text style={{position:'absolute',marginTop:'30px',fontSize:'11px',color:'red',width:'285px',textAlign:'center'}}>Error. Passowrd incorrect for that email. Please try again.</text>
                    :null}

              <div style={{borderStyle:'solid',borderWidth:'0px',borderBottomWidth:'1px',borderColor:'#dedede',height:'.25px',width:'150px',marginTop:'25px',marginBottom:'15px'}} />


                    <div
                    onClick={()=>{this.setState({selectingPage:3})}}
                    className="LogInButton" onMouseOver={()=>{this.setState({mouseOverLogInButton:true})}} onMouseLeave={()=>{this.setState({mouseOverLogInButton:false})}}
                    style={{background:this.state.mouseOverLogInButton?'linear-gradient(-128deg, #00F5C3 0%, #00E0EE 100%)':null,backgroundColor:this.state.mouseOverLogInButton?'white':null,width:'220px',height:'40px',borderRadius:'8px',borderStyle:'solid',borderColor:this.state.mouseOverLogInButton?'white':"#e4e4e4",borderWidth:'1px',
                    display:'flex',marginTop:'0px',flexDirection:'column',alignItems:'center',justifyContent:'center',cursor:'pointer'}}>

                      <text className="bodyNormal" style={{fontSize:'15px',color:this.state.mouseOverLogInButton?'white':'black'}}>Log In</text>

                    </div>




                    <div
                      onClick={()=>{this.setState({selectingPage:2})}}
                    className="LogInButton" onMouseOver={()=>{this.setState({mouseOverCreateAccountButton:true})}} onMouseLeave={()=>{this.setState({mouseOverCreateAccountButton:false})}}
                    style={{background:this.state.mouseOverCreateAccountButton?'linear-gradient(-128deg, #00F5C3 0%, #00E0EE 100%)':null,backgroundColor:this.state.mouseOverCreateAccountButton?'white':null,width:'220px',height:'40px',borderRadius:'8px',borderStyle:'solid',borderColor:this.state.mouseOverCreateAccountButton?'white':"#e4e4e4",borderWidth:'1px',
                    display:'flex',marginTop:'8px',flexDirection:'column',alignItems:'center',justifyContent:'center',cursor:'pointer',marginBottom:'50px'}}>

                      <text className="bodyNormal" style={{fontSize:'15px',color:this.state.mouseOverCreateAccountButton?'white':'black'}}>Create Account</text>

                    </div>

                    <text className="hoverLight" style={{position:'absolute',marginTop:'190px',fontSize:'12px',color:'blue',width:'285px',textAlign:'center',cursor:'pointer'}}>Forgot Password?</text>


            </div>

          :
                this.state.selectingPage===2?
                <Grow in = {this.state.selectingPage===2} timeout={400} >


                  <div style={{width:"86%",borderStyle:'solid',borderWidth:'0px',borderColor:'#d6d6d6',borderLeftWidth:'0px',borderRightWidth:'0px',borderBottomWidth:'1px',height:'512px',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>


                          <text className="bodyNormal" style={{fontSize:'12px',color:'#aeaeae',textAlign:'center',paddingLeft:'20px',paddingRight:'20px',marginBottom:'15px'}}>Welcome, usagriffin@gmail.com</text>


                          <text onClick={()=>{this.setState({open:true})}} className="bodyNormal" style={{fontSize:'26px',color:'black',textAlign:'center',paddingLeft:'20px',paddingRight:'20px'}}>Add Payment Method</text>

                    <div style={{borderStyle:'solid',borderWidth:'0px',borderBottomWidth:'1px',borderColor:'#dedede',height:'.25px',width:'150px',marginTop:'20px',marginBottom:'7.5px'}} />

                    {/*

                    <ReactPlaid
                    open={this.state.open}
                    onExit={() => this.setState({open: false})}
                    apiKey='19d9ef3ca172750883aa833c1e02e4'
                    env="sandbox"
                    product="transactions"
                    clientName="Nifty Gateway"
                    onSuccess={(public_token,metaData)=>{console.log('token',public_token,'metadata',metaData)}}
                     />

                     */}

                          <text className="bodyNormal" style={{fontSize:'17px',color:'#444',textAlign:'center',paddingLeft:'20px',paddingRight:'20px',marginBottom:'10px'}}>Credit Card</text>

                          {this.state.detectedCreditCardBrand===null?
                      <img src={Default} className="CardIcon"  style={{marginBottom:'10px',width:'50px',objectFit:'cover'}} />:
                        this.state.detectedCreditCardBrand==="Visa"?
                        <img src={Visa} className="CardIcon"  style={{marginBottom:'10px',width:'50px',objectFit:'cover'}} />
                        :
                          this.state.detectedCreditCardBrand==="Mastercard"?
                          <img src={Mastercard} className="CardIcon"  style={{marginBottom:'10px',width:'50px',objectFit:'cover'}} />
                          :
                          this.state.detectedCreditCardBrand==="American Express"?
                          <img src={Amex} className="CardIcon"  style={{marginBottom:'10px',width:'50px',objectFit:'cover'}} />
                          :
                          <img src={Default} className="CardIcon"  style={{marginBottom:'10px',width:'50px',objectFit:'cover'}} />
                      }


                          <text className="bodyNormal" style={{fontSize:'13px',color:'grey',marginTop:'0px',marginBottom:'2px',alignSelf:'flex-start',marginLeft:'40px'}}>Credit Card Number</text>
                          <input
                          onChange={(event)=>{this.setState({CCNum:cc_format(event.target.value)});this.handleValidateCCInputs();this.detectedCreditCardBrandFunctionInComponent(event.target.value)}} value={this.state.CCNum}
                          className="bodyNormal" name="cardnumber" autocomplete="cc-number" id="frmCCNum" style={{paddingLeft:'5px',height:'30px',width:'220px',borderStyle:'solid',borderWidth:'1px',borderColor:'#dedede',borderRadius:'4px',fontSize:'14px'}}
                          />


                          <text className="bodyNormal" style={{fontSize:'13px',color:'grey',marginTop:'5px',marginBottom:'2px',alignSelf:'flex-start',marginLeft:'40px'}}>Expiration Date</text>
                          <input
                          value={this.state.CCExpDate} onChange={(event)=>{this.setState({CCExpDate:event.target.value});this.handleValidateCCInputs()}}
                          className="bodyNormal"  name="cc-exp"  autocomplete="cc-exp"   style={{paddingLeft:'5px',height:'30px',width:'220px',borderStyle:'solid',borderWidth:'1px',borderColor:'#dedede',borderRadius:'4px',fontSize:'14px'}} />

                          <text className="bodyNormal" style={{fontSize:'13px',color:'grey',marginTop:'5px',marginBottom:'2px',alignSelf:'flex-start',marginLeft:'40px'}}>CVC</text>
                          <input
                          value={this.state.CCCVC} onChange={(event)=>{this.setState({CCCVC:event.target.value});this.handleValidateCCInputs()}}
                          className="bodyNormal" name="cvc"  id="frmCCCVC" autocomplete="cc-csc" style={{paddingLeft:'5px',height:'30px',width:'220px',borderStyle:'solid',borderWidth:'1px',borderColor:'#dedede',borderRadius:'4px',fontSize:'14px'}} />

                    <div style={{borderStyle:'solid',borderWidth:'0px',borderBottomWidth:'1px',borderColor:'#dedede',height:'.25px',width:'150px',marginTop:'30px',marginBottom:'30px'}} />

                      {this.state.approveClickAddButtonOnAddPamyentSourcePage?
                          <div

                          onClick={()=>{var curPage=this.state.selectingPage;var newPage = curPage +1;this.setState({selectingPage:newPage})}}
                          className="LogInButton" onMouseOver={()=>{this.setState({mouseOverAddSourceButton:true})}} onMouseLeave={()=>{this.setState({mouseOverAddSourceButton:false})}}
                          style={{background:this.state.mouseOverAddSourceButton?'linear-gradient(-128deg, #00F5C3 0%, #00E0EE 100%)':null,backgroundColor:this.state.mouseOverAddSourceButton?'white':null,width:'220px',height:'40px',borderRadius:'8px',borderStyle:'solid',borderColor:this.state.mouseOverAddSourceButton?'white':"#e4e4e4",borderWidth:'1px',
                          display:'flex',marginTop:'0px',flexDirection:'column',alignItems:'center',justifyContent:'center',cursor:'pointer'}}>

                            <text className="bodyNormal" style={{fontSize:'15px',color:this.state.mouseOverAddSourceButton?'white':'black'}}>Add</text>

                          </div>
                        :
                        <div


                        className="LogInButton"
                        style={{background:this.state.mouseOverAddSourceButton?'linear-gradient(-128deg, #00F5C3 0%, #00E0EE 100%)':null,backgroundColor:this.state.mouseOverAddSourceButton?'white':null,width:'220px',height:'40px',borderRadius:'8px',borderStyle:'solid',borderColor:this.state.mouseOverAddSourceButton?'white':"#e4e4e4",borderWidth:'1px',
                        display:'flex',marginTop:'0px',flexDirection:'column',alignItems:'center',justifyContent:'center',cursor:'default'}}>

                          <text className="bodyNormal" style={{fontSize:'15px',color:this.state.mouseOverAddSourceButton?'white':'grey'}}>Add</text>

                        </div>
                      }



                  </div>


                </Grow>
                  :
                    this.state.selectingPage===3?

                  <Fade in = {this.state.selectingPage===3} timeout={200} >


                  <div className='background-leave' style={{zIndex:1,width:'86%'}}>
                  <div style={{width:"100%",borderStyle:'solid',borderWidth:'0px',borderColor:'#d6d6d6',borderBottomWidth:'1px',height:'155px',display:'flex',flexDirection:'column',alignItems:'flex-start',justifyContent:'flex-start'}}>

                            <div style={{width:"300px",borderStyle:'solid',borderWidth:'0px',borderColor:'#d6d6d6',borderBottomWidth:'0px',height:'165px',display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center',flex:1}}>

                                <div style={{flex:.49,width:'140px',borderStyle:'solid',borderWidth:'0px',borderColor:'#d6d6d6',borderBottomWidth:'0px',height:'142px',display:'flex',flexDirection:'column',alignItems:'flex-start',justifyContent:'flex-start'}}>


                                      <text className="bodyNormal" style={{fontSize:'14.3px',marginTop:'5px'}}>Buying:</text>

                                      <div style={{width:'100%',display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center',marginTop:'10px',whiteSpace:"no-wrap",overflow:"hidden",textOverflow:"ellipsis"}}>

                                          <img src={'https://s3.amazonaws.com/matterhornphotostorage/95792.png'} style={{width:'54px',height:'54px',borderRadius:'3px'}} />
                                          <text className="bodyNormal" style={{fontSize:'12.5px',marginLeft:'5px',width:'80px',height:'51px',whiteSpace:"no-wrap",overflow:"hidden",textOverflow:"ellipsis",paddingRight:'2px',alignSelf:"center",display:'flex',flexDirection:'column',justifyContent:'center'}}>Yasil Puig</text>

                                      </div>

                                      <text className="bodyNormal" style={{fontSize:'12.5px',marginTop:'10px'}}>From:</text>
                                      <text className="bodyNormal" style={{fontSize:'12.5px',marginTop:'5px',height:'34px',display:'flex',flexDirection:'column',justifyContent:"center",overflow:"hidden",textOverflow:"ellipsis",}}>MLB Crypto Baseball</text>

                                </div>

                                <div style={{flex:.01,height:'100px',width:'0px',borderStyle:'solid',borderWidth:'0px',borderColor:'#dedede',borderLeftWidth:'1px'}}></div>

                                <div style={{flex:.49,width:'140px',paddingLeft:'15px',borderStyle:'solid',borderWidth:'0px',borderColor:'#d6d6d6',borderBottomWidth:'0px',height:'142px',display:'flex',flexDirection:'column',alignItems:'flex-start',justifyContent:'flex-start'}}>

                                  <text className="bodyNormal" style={{fontSize:'14.3px',marginTop:'5px'}}>Cost:</text>

                                  <text className="bodyNormal" style={{fontSize:'12.5px',marginTop:'20px',color:'#BBBBBB'}}>Subtotal: $23.00</text>
                                  <text className="bodyNormal" style={{fontSize:'12.5px',marginTop:'10px',color:'#BBBBBB'}}>Transaction Fee: $1.87</text>

                                  <text className="bodyNormal" style={{fontSize:'12.5px',marginTop:'28.5px'}}>You'll Pay: <text style={{fontSize:'16.5px'}}>$24.87</text></text>

                                </div>


                              </div>


                  </div>

                  <div style={{width:"100%",borderStyle:'solid',borderWidth:'0px',borderColor:'#d6d6d6',borderBottomWidth:'1px',height:'154px',display:'flex',flexDirection:'column',alignItems:'flex-start',justifyContent:'flex-start'}}>

                      <text className="bodyNormal" style={{fontSize:'14.3px',marginTop:'10px'}}>Payment Method:</text>

                      <div onClick={()=>{this.setState({selectingPage:5,mouseOverAddSourceButton:false})}} style={{cursor:'pointer',width:'100%',marginTop:'15px',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>

                        <text className="bodyNormal" style={{fontSize:'9px',color:'#cecece'}}></text>
                        <div style={{width:'100%',marginTop:'2px',display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'flex-start',borderStyle:'solid',borderColor:'#dedede',borderWidth:'1px',borderRadius:'5px',height:'45px'}}>
                        <img src={'https://s3.amazonaws.com/matterhornphotostorage/bofalogocolorgoodqual.png'} style={{objectFit:'contain',height:'25px',width:'130px',marginLeft:'15px'}} />
                        <text style={{marginLeft:'7.5px',fontSize:'13px',color:'#999'}}>Acct ending in {this.state.paymentSourcesArray[this.state.selectedPaymentIndex]}</text>
                        </div>
                      </div>

                      <div onClick={()=>{this.setState({selectingPage:5,mouseOverChangePaymentMethodButton:false,mouseOverAddSourceButton:false})}} onMouseOver={()=>{this.setState({mouseOverChangePaymentMethodButton:true})}} onMouseLeave={()=>{this.setState({mouseOverChangePaymentMethodButton:false})}}
                      style={{cursor:"pointer",backgroundColor:this.state.mouseOverChangePaymentMethodButton?'#eee':'white',width:'50%',marginTop:'10px',display:'flex',flexDirection:'column',alignSelf:"center",alignItems:'center',justifyContent:'center',borderStyle:'solid',borderColor:'#dedede',borderWidth:this.state.mouseOverChangePaymentMethodButton?'0px':'1px',borderRadius:'0px',height:'25px',marginTop:'10px'}}>

                      <text style={{fontSize:'10px',color:this.state.mouseOverChangePaymentMethodButton?'white':'black'}}>Change Payment Method</text>
                      </div>

                  </div>

                  <div style={{width:"100%",borderStyle:'solid',borderWidth:'0px',borderColor:'#d6d6d6',borderBottomWidth:'1px',height:'171px',display:'flex',flexDirection:'column',alignItems:'flex-start',justifyContent:'flex-start'}}>

                      <text className="bodyNormal" style={{fontSize:'14.3px',marginTop:'10px'}}>Destination:</text>


                      {this.state.walletSelectionIndex===0?
                        <div
                        style={{width:'100%',marginTop:'7.5px',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
                        <img onClick={()=>{this.setState({walletSelectionIndex:1,mouseOverSendToNiftyGatewayWalletButton:false})}}
                        src={'https://s3.amazonaws.com/matterhornphotostorage/FaviconNFTG.png'} style={{cursor:'pointer',alignSelf:"center",width:'40px',height:'40px',marginBottom:'5px'}} />
                        <text
                        onClick={()=>{this.setState({walletSelectionIndex:1,mouseOverSendToNiftyGatewayWalletButton:false})}}
                        className="bodyBold" style={{cursor:'pointer',fontSize:'19px',color:'black',alignSelf:'center'}}>Your Nifty Gateway Wallet</text>

                            <div onClick={()=>{this.setState({walletSelectionIndex:1,mouseOverSendToNiftyGatewayWalletButton:false})}}
                            onMouseOver={()=>{this.setState({mouseOverSendToMetaMaskWalletButton:true})}} onMouseLeave={()=>{this.setState({mouseOverSendToMetaMaskWalletButton:false})}}
                             style={{cursor:'pointer',width:'80%',marginTop:'10px',display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center',borderStyle:'solid',borderColor:'#eee',borderWidth:'0px',borderTopWidth:'1px'}}>

                                <img src={'https://s3.amazonaws.com/matterhornphotostorage/GreyedOutMetamaskLogo.png'} style={{alignSelf:"center",width:'20px',height:'20px',marginTop:'10px',opacity:this.state.mouseOverSendToMetaMaskWalletButton?'.3':'1'}} />
                                <text className="bodyNormal" style={{marginLeft:'5px',fontSize:'15px',color:this.state.mouseOverSendToMetaMaskWalletButton?'#eee':'#cecece',marginTop:'10px',alignSelf:'center'}}>Send to your Metamask Wallet</text>
                            </div>
                        </div>

                        :
                        <div style={{width:'100%',marginTop:'7.5px',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
                        <img
                        onClick={()=>{this.setState({walletSelectionIndex:0,mouseOverSendToMetaMaskWalletButton:false})}}
                        src={'https://s3.amazonaws.com/matterhornphotostorage/NotGreyedOutMetamaskLogo.png'} style={{cursor:'pointer',alignSelf:"center",width:'40px',height:'40px',marginBottom:'5px'}} />
                        <text
                        onClick={()=>{this.setState({walletSelectionIndex:0,mouseOverSendToMetaMaskWalletButton:false})}}
                        className="bodyBold" style={{cursor:'pointer',fontSize:'19px',color:'black',alignSelf:'center'}}>Your Metamask Wallet</text>

                            <div onClick={()=>{this.setState({walletSelectionIndex:0,mouseOverSendToMetaMaskWalletButton:false})}}
                            onMouseOver={()=>{this.setState({mouseOverSendToNiftyGatewayWalletButton:true})}} onMouseLeave={()=>{this.setState({mouseOverSendToNiftyGatewayWalletButton:false})}}
                             style={{cursor:'pointer',width:'80%',marginTop:'10px',display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center',borderStyle:'solid',borderColor:'#eee',borderWidth:'0px',borderTopWidth:'1px'}}>

                                <img src={'https://s3.amazonaws.com/matterhornphotostorage/GreyedOutNiftyGatewayLogo.png'} style={{alignSelf:"center",width:'20px',height:'20px',marginTop:'10px',opacity:this.state.mouseOverSendToNiftyGatewayWalletButton?'.3':'1'}} />
                                <text className="bodyNormal" style={{marginLeft:'5px',fontSize:'13px',color:this.state.mouseOverSendToNiftyGatewayWalletButton?'#eee':'#cecece',marginTop:'10px',alignSelf:'center'}}>Send to your Nifty Gateway Wallet</text>
                            </div>
                        </div>

                      }



                  </div>
                  </div>
                  </Fade>

                  :
                      this.state.selectingPage===4?
                      <Grow in = {this.state.selectingPage===4} timeout={400} >


                        <div style={{width:"86%",borderStyle:'solid',borderWidth:'0px',borderColor:'#d6d6d6',borderLeftWidth:'0px',borderRightWidth:'0px',borderBottomWidth:'1px',height:'598px',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>


                                <text className="bodyNormal" style={{fontSize:'29px',color:'black',textAlign:'center',padding:'20px',marginTop:'-150px'}}>Payment Successful</text>

                                <text className="bodyNormal" style={{fontSize:'79px',color:'black',textAlign:'center',padding:'20px'}}>👍</text>


                        </div>


                      </Grow>
                      :
                        this.state.selectingPage===5?
                        <Grow in = {this.state.selectingPage===5} timeout={250}>
                        <div style={{width:"86%",borderStyle:'solid',borderWidth:'0px',borderColor:'#d6d6d6',borderLeftWidth:'0px',borderRightWidth:'0px',borderBottomWidth:'1px',height:'512px',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>

                          <text className="hoverLight" onClick={()=>{this.setState({selectingPage:3})}} style={{position:'absolute',marginTop:'-225px',marginLeft:'-3px',alignSelf:"flex-start",fontSize:'14px',color:'#44BEEC',textAlign:'left',paddingLeft:'0px',paddingRight:'10px'}}><FaChevronLeft style={{color:"#44BEEC",marginBottom:'-2.5px',marginRight:'2px'}} />Back</text>

                                <text className="bodyNormal" style={{fontSize:'26px',color:'black',textAlign:'center',paddingLeft:'20px',paddingRight:'20px',width:'300px',marginTop:'20px'}}>Choose Payment Method</text>
                                <text className="bodyNormal" style={{fontSize:'13px',color:'#aeaeae',textAlign:'center',paddingLeft:'20px',paddingRight:'20px',width:'300px'}}>{this.state.paymentSourcesArray.length} payment sources available</text>

                          <div style={{borderStyle:'solid',borderWidth:'0px',borderBottomWidth:'1px',borderColor:'#dedede',height:'.25px',width:'150px',marginTop:'10px',marginBottom:'15px'}} />
                            <div style={{height:'255px',overflow:'scroll'}}>
                              {this.state.paymentSourcesArray.map((l,i) => (
                                <div onClick={()=>{this.setState({selectedPaymentIndex:i,selectingPage:3});var curArray = this.state.mouseOverPaymentSourcesArray; curArray[i] = false; this.setState({mouseOverPaymentSourcesArray:curArray})}} onMouseOver={()=>{var curArray = this.state.mouseOverPaymentSourcesArray; curArray[i] = true; this.setState({mouseOverPaymentSourcesArray:curArray})}} onMouseLeave={()=>{var curArray = this.state.mouseOverPaymentSourcesArray; curArray[i] = false; this.setState({mouseOverPaymentSourcesArray:curArray})}}  style={{width:'295px',marginTop:'2px',display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'flex-start',cursor:'pointer',borderStyle:'solid',borderColor:this.state.mouseOverPaymentSourcesArray[i]===true?'#e41033':'#dedede',borderWidth:'1px',borderRadius:'5px',height:'45px',opacity:'1'}}>
                                <img src={this.state.mouseOverPaymentSourcesArray[i]===true?'https://s3.amazonaws.com/matterhornphotostorage/bofalogocolorgoodqual.png':'https://s3.amazonaws.com/matterhornphotostorage/bofalogogreygoodqual.png'} style={{objectFit:'contain',height:'25px',width:'130px',marginLeft:'15px'}} />
                                <text style={{marginLeft:'7.5px',fontSize:'13px',color:this.state.mouseOverPaymentSourcesArray[i]===true?'#E41033':'#999'}}>Acct ending in {l}</text>
                                </div>
                              ))}
                            </div>


                          <div style={{borderStyle:'solid',borderWidth:'0px',borderBottomWidth:'1px',borderColor:'#dedede',height:'.25px',width:'150px',marginTop:'20px',marginBottom:'20px'}} />

                                <div

                                onClick={()=>{this.setState({approveClickAddButtonOnCFullFeaturedAccountAddPamyentSourcePage:false,CCNum:null,CCExpDate:null,CCCVC:null,mouseOverAddSourceButton:false,detectedCreditCardBrand:null,selectingPage:6})}}
                                className="LogInButton" onMouseOver={()=>{this.setState({mouseOverAddSourceButton:true})}} onMouseLeave={()=>{this.setState({mouseOverAddSourceButton:false})}}
                                style={{background:this.state.mouseOverAddSourceButton?'linear-gradient(-128deg, #00F5C3 0%, #00E0EE 100%)':null,backgroundColor:this.state.mouseOverAddSourceButton?'white':null,width:'220px',height:'40px',borderRadius:'8px',borderStyle:'solid',borderColor:this.state.mouseOverAddSourceButton?'white':"#e4e4e4",borderWidth:'1px',
                                display:'flex',marginTop:'0px',flexDirection:'column',alignItems:'center',justifyContent:'center',cursor:'pointer'}}>

                                  <text className="bodyNormal" style={{fontSize:'15px',color:this.state.mouseOverAddSourceButton?'white':'black'}}>Add New Payment Method</text>

                                </div>

                        </div>
                        </Grow>
                        :
                        <div style={{width:"86%",borderStyle:'solid',borderWidth:'0px',borderColor:'#d6d6d6',borderLeftWidth:'0px',borderRightWidth:'0px',borderBottomWidth:'1px',height:'512px',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>

                        <text className="hoverLight" onClick={()=>{this.setState({selectingPage:5})}} style={{position:'absolute',marginTop:'-225px',marginLeft:'-3px',alignSelf:"flex-start",fontSize:'14px',color:'#44BEEC',textAlign:'left',paddingLeft:'0px',paddingRight:'10px'}}><FaChevronLeft style={{color:"#44BEEC",marginBottom:'-2.5px',marginRight:'2px'}} />Back</text>


                                <text className="bodyNormal" style={{fontSize:'12px',color:'#aeaeae',textAlign:'center',paddingLeft:'20px',paddingRight:'20px',marginBottom:'15px'}}>Welcome, usagriffin@gmail.com</text>


                                <text className="bodyNormal" style={{fontSize:'26px',color:'black',textAlign:'center',paddingLeft:'20px',paddingRight:'20px'}}>Add Payment Method</text>

                          <div style={{borderStyle:'solid',borderWidth:'0px',borderBottomWidth:'1px',borderColor:'#dedede',height:'.25px',width:'150px',marginTop:'20px',marginBottom:'7.5px'}} />

                                <text className="bodyNormal" style={{fontSize:'17px',color:'#444',textAlign:'center',paddingLeft:'20px',paddingRight:'20px',marginBottom:'10px'}}>Credit Card</text>

                                        {this.state.detectedCreditCardBrand===null?
                                    <img src={Default} className="CardIcon"  style={{marginBottom:'10px',width:'50px',objectFit:'cover'}} />:
                                      this.state.detectedCreditCardBrand==="Visa"?
                                      <img src={Visa} className="CardIcon"  style={{marginBottom:'10px',width:'50px',objectFit:'cover'}} />
                                      :
                                        this.state.detectedCreditCardBrand==="Mastercard"?
                                        <img src={Mastercard} className="CardIcon"  style={{marginBottom:'10px',width:'50px',objectFit:'cover'}} />
                                        :
                                        this.state.detectedCreditCardBrand==="American Express"?
                                        <img src={Amex} className="CardIcon"  style={{marginBottom:'10px',width:'50px',objectFit:'cover'}} />
                                        :
                                        <img src={Default} className="CardIcon"  style={{marginBottom:'10px',width:'50px',objectFit:'cover'}} />
                                    }

                                <text className="bodyNormal" style={{fontSize:'13px',color:'grey',marginTop:'0px',marginBottom:'2px',alignSelf:'flex-start',marginLeft:'40px'}}>Credit Card Number</text>
                                <input
                                onChange={(event)=>{this.setState({CCNum:cc_format(event.target.value)});this.handleValidateCCInputs();this.detectedCreditCardBrandFunctionInComponent(event.target.value)}} value={this.state.CCNum}
                                className="bodyNormal" name="cardnumber" autocomplete="cc-number" id="frmCCNum" style={{paddingLeft:'5px',height:'30px',width:'220px',borderStyle:'solid',borderWidth:'1px',borderColor:'#dedede',borderRadius:'4px',fontSize:'14px'}}
                                />


                                <text className="bodyNormal" style={{fontSize:'13px',color:'grey',marginTop:'5px',marginBottom:'2px',alignSelf:'flex-start',marginLeft:'40px'}}>Expiration Date</text>
                                <input
                                value={this.state.CCExpDate} onChange={(event)=>{this.setState({CCExpDate:event.target.value});this.handleValidateCCInputs();this.detectedCreditCardBrandFunctionInComponent(event.target.value)}}
                                className="bodyNormal"  name="cc-exp"  autocomplete="cc-exp"   style={{paddingLeft:'5px',height:'30px',width:'220px',borderStyle:'solid',borderWidth:'1px',borderColor:'#dedede',borderRadius:'4px',fontSize:'14px'}} />

                                <text className="bodyNormal" style={{fontSize:'13px',color:'grey',marginTop:'5px',marginBottom:'2px',alignSelf:'flex-start',marginLeft:'40px'}}>CVC</text>
                                <input
                                value={this.state.CCCVC} onChange={(event)=>{this.setState({CCCVC:event.target.value});this.handleValidateCCInputs();this.detectedCreditCardBrandFunctionInComponent(event.target.value)}}
                                className="bodyNormal" name="cvc"  id="frmCCCVC" autocomplete="cc-csc" style={{paddingLeft:'5px',height:'30px',width:'220px',borderStyle:'solid',borderWidth:'1px',borderColor:'#dedede',borderRadius:'4px',fontSize:'14px'}} />

                          <div style={{borderStyle:'solid',borderWidth:'0px',borderBottomWidth:'1px',borderColor:'#dedede',height:'.25px',width:'150px',marginTop:'30px',marginBottom:'30px'}} />

                            {this.state.approveClickAddButtonOnAddPamyentSourcePage?
                                <div

                                onClick={()=>{this.setState({selectingPage:3})}}
                                className="LogInButton" onMouseOver={()=>{this.setState({mouseOverAddSourceButton:true})}} onMouseLeave={()=>{this.setState({mouseOverAddSourceButton:false})}}
                                style={{background:this.state.mouseOverAddSourceButton?'linear-gradient(-128deg, #00F5C3 0%, #00E0EE 100%)':null,backgroundColor:this.state.mouseOverAddSourceButton?'white':null,width:'220px',height:'40px',borderRadius:'8px',borderStyle:'solid',borderColor:this.state.mouseOverAddSourceButton?'white':"#e4e4e4",borderWidth:'1px',
                                display:'flex',marginTop:'0px',flexDirection:'column',alignItems:'center',justifyContent:'center',cursor:'pointer'}}>

                                  <text className="bodyNormal" style={{fontSize:'15px',color:this.state.mouseOverAddSourceButton?'white':'black'}}>Add</text>

                                </div>
                                :
                                <div


                                className="LogInButton"
                                style={{background:this.state.mouseOverAddSourceButton?'linear-gradient(-128deg, #00F5C3 0%, #00E0EE 100%)':null,backgroundColor:this.state.mouseOverAddSourceButton?'white':null,width:'220px',height:'40px',borderRadius:'8px',borderStyle:'solid',borderColor:this.state.mouseOverAddSourceButton?'white':"#e4e4e4",borderWidth:'1px',
                                display:'flex',marginTop:'0px',flexDirection:'column',alignItems:'center',justifyContent:'center',cursor:'default'}}>

                                  <text className="bodyNormal" style={{fontSize:'15px',color:this.state.mouseOverAddSourceButton?'white':'grey'}}>Add</text>

                                </div>
                              }

                        </div>



          }


          {this.state.selectingPage===3?
            <Fade in = {this.state.selectingPage===3} timeout={1000} >
            <div onClick={()=>{var curPage=this.state.selectingPage;var newPage = curPage +1;this.setState({selectingPage:newPage});setTimeout(()=>{window.close()},1200)}} style={{opacity:this.state.selectingPage===3?'1':'.4',width:'293px',height:'86px',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>


              <div className="completePaymentButton" onMouseOver={()=>{this.setState({mouseOverCompletePaymentButton:true})}} onMouseLeave={()=>{this.setState({mouseOverCompletePaymentButton:false})}}
              style={{background:this.state.mouseOverCompletePaymentButton?'linear-gradient(-128deg, #00F5C3 0%, #00E0EE 100%)':null,backgroundColor:this.state.mouseOverCompletePaymentButton?'white':null,width:'100%',height:'58px',borderRadius:'10px',borderStyle:'solid',borderColor:this.state.mouseOverCompletePaymentButton?'white':"#e4e4e4",borderWidth:'1px',
              display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center',cursor:'pointer'}}>

                <text className={this.state.mouseOverCompletePaymentButton?"bodyNormal":"bodyNormal"} style={{fontSize:'17px',color:this.state.mouseOverCompletePaymentButton?'white':'black'}}>Confirm Payment - $24.87</text>

              </div>
            </div>

            </Fade>
          :
          this.state.selectingPage===4?
          null:
          <div style={{opacity:this.state.selectingPage===3?'1':'.4',width:'293px',height:'86px',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>


            <div className="completePaymentButton"
            style={{background:this.state.mouseOverCompletePaymentButton?'linear-gradient(-128deg, #00F5C3 0%, #00E0EE 100%)':null,backgroundColor:this.state.mouseOverCompletePaymentButton?'white':null,width:'100%',height:'58px',borderRadius:'10px',borderStyle:'solid',borderColor:this.state.mouseOverCompletePaymentButton?'white':"#e4e4e4",borderWidth:'1px',
            display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center',cursor:'default'}}>

              <text className={this.state.mouseOverCompletePaymentButton?"bodyNormal":"bodyNormal"} style={{fontSize:'17px',color:this.state.mouseOverCompletePaymentButton?'white':'grey'}}>Confirm Payment - $24.87</text>

            </div>
          </div>
        }

      </div>

      <div style={{height:'5px',marginTop:'5px',width:'340px',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'flex-start',}}>

        <text className='bodyNormal' style={{fontSize:'13px',color:"white"}}>Questions? Reach out to support@niftygateway.com</text>


      </div>


      </div>


    );
  }
}

export default BuyButtonFullFlow;
