import React, { Component } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import {NavLink,Switch,HashRouter} from "react-router-dom";

import { browserHistory } from 'react-router';

import BuyButtonDesktop from './buyButtonWindow.js';
import BuyButtonPreLogin from './buyButtonWindowPreLogin.js';
import BuyButtonAddPaymentSource from './buyButtonWindowAddPaymentSource.js';
import BuyButtonFullFlow from './buyButtonWindowFullFlow.js';
import DemoHomePage from './DemoHomePage.js';
import BuyButtonSandboxLiveHomePage from './LiveSandboxDemo.js';
import DemoHomePageCompact from './DemoHomePageCompacy.js';
import DashboardIndividualWeek from './DashboardIndividualWeek.js';
import Dashboard1Year from './DashboardPastYear.js';
import DashboardInvidualUser from './DashboardUserProfile.js';
import DashboardDod from './DasboardDoD.js';
import DashboardDodPast90 from './DasboardDoDPast90.js';
import DashboardSortByUsers from './DashboardSortByUsers.js';
import DashboardSortByGames from './DashboardSortByGames.js';
import DashboardIndividualDay from './DashboardIndividualDay.js';

import Dashboard from './DashboardWow.js';
import DashboardMoM from './MonthOverMonth.js';

import { withRouter } from 'react-router-dom'

class DesktopHome extends React.Component {
  render(){
    return(

      <div style={{display:'flex',flexDirection:"column",alignItems:'center',justifyContent:'center'}}>

      <img style={{opacity:'1',width:'1450px',height:'700px',objectFit:'cover'}} src={'https://s3.amazonaws.com/matterhornphotostorage/godsunchainedbckrg.png'} />


      <div
      className="hoverLight"
      onClick={()=>{window.open('https://niftygateway.com/#/Redirect/BuyButtonRedirectURL/niftyname=Presale%20Rare%20Pack%20-%206&niftyImageURL=https://s3.amazonaws.com/matterhornphotostorage/rare-center.png&niftySellerName=Gods%20Unchained&niftyPriceInCents=800&niftyContractAddress=xxx&','mywin','width=400,height=800')}}
      style={{cursor:"pointer",position:'absolute',marginTop:'205px',marginLeft:'280.25px',width:'415px',height:'65px',borderStyle:'solid',borderWidth:'1px',borderColor:'#6CC8C0',backgroundColor:'white',zIndex:2,display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center',backgroundImage:'linear-gradient(-180deg, #2A3F39 0%, #030D13 100%)'}}>
      <text className="bodyBold" style={{fontSize:'27px',color:'white'}}>Buy with USD - $8.00</text>
      </div>


      </div>


    )
  }
}

function NoComponenet() {
  return (
    <div>
      <h2>_</h2>
    </div>
  );
}



class App extends Component {

  constructor(){
    super();
    this.state={

    }

  }



  render() {


    return (

        <HashRouter>
        <div  style={{display:'flex',flexDirection:'column',flex:1}}>


                    {//  <TopNavBar isUserLoggedIn={false} /> }
                  }

                      <div style={{marginTop:'0px'}}>

                        <Route exact  path="/" component={DemoHomePageCompact} />
                        <Route path = "/compact" component={DemoHomePageCompact} />
                        <Route   path="/home" component={DesktopHome} />

                        <Route path="/buyButtonDesktop" component={BuyButtonDesktop} />
                        <Route path="/BuyButtonPreLogin" component={BuyButtonPreLogin} />



                      </div>

                <div>

                </div>

          </div>

          </HashRouter>

    );

  }
}

export default App;
